import { Checkbox, Divider, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { findUser } from "../../../slices/user.slice";
import { useNavigate } from "react-router-dom";
import services from "../../../services";
import IssueView from "../../../components/dashboard/inbox/IssueView";

export default function Inbox() {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [issues, setIssues] = useState([]);
  const [selectedIssue, setSelectedIssue] = useState(null);
  const [isLoading, setIsLoading] = useState([true]);

  useEffect(() => {
    if (user.status === "idle") {
      dispatch(
        findUser({
          parameters: {
            id: sessionStorage.getItem("id"),
          },
          navigate,
        }),
      );
    }
    if (user.status === "success") {
      console.log("USER FULFILLED");
      console.log(user.data.projects);
      setProjects(user.data.projects);
    }
  }, [user.status]);

  useEffect(() => {
    async function setup() {
      for (const project of projects) {
        const response = await services.accounts.projects.single.issues.find({
          id: project._id,
        });
        if (response.status === 200) {
          if (response.data.data.issues[0]) {
            setSelectedIssue(response.data.data.issues[0]);
          }
          setIssues((issues) => [...issues, ...response.data.data.issues]);
        }
      }
      setIsLoading((isLoading) => {
        setIsLoading[0] = false;
        return [...isLoading];
      });
    }
    setup();
  }, [projects]);

  useEffect(() => {
    console.log(selectedIssue);
  }, [selectedIssue]);

  return (
    <div className={"bg-white h-full border border-gray-300 overflow-hidden"}>
      <div className={"flex w-full h-full"}>
        <div
          className={
            "bg-neutral-50 w-full max-w-[400px] h-full overflow-y-auto no-scrollbar"
          }
        >
          <div className={"px-6 py-4"}>
            <p className={"text-lg font-semibold"}>Inbox</p>
          </div>
          <div className={"px-6"}>
            <TextField variant={"outlined"} size={"small"} />
          </div>
          <div className={"px-6 py-3 flex items-center justify-between"}>
            <div></div>
            <p className={"text-xs font-light"}>{`${issues.length} Items`}</p>
          </div>
          <div className={"px-0"}>
            {issues.map((issue) => {
              return (
                <>
                  <Divider />
                  <div
                    className={
                      "px-6 py-3 flex justify-between hover:bg-gray-200 cursor-pointer"
                    }
                    onClick={() => {
                      setSelectedIssue(issue);
                    }}
                  >
                    <div className={"flex items-center gap-x-2"}>
                      <div>
                        <p className={"text-xs font-light text-gray-500"}>
                          {issue.project.title}
                        </p>
                        <p className={"text-sm"}>{issue.summary}</p>
                      </div>
                    </div>
                    <div className={"flex"}>
                      <p className={"text-xs font-light"}>
                        {issue.createdBy.emailAddress}
                      </p>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
        <div className={"w-full h-full overflow-y-auto no-scrollbar"}>
          <IssueView issue={selectedIssue} />
        </div>
      </div>
    </div>
  );
}
