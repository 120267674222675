import { Outlet } from "react-router-dom";

export default function LayoutV1(props) {
  return (
    <div className={"w-full h-full fixed flex"}>
      <div className={"w-[50%]"}>
        <div className="bg-blue-700 min-h-screen flex flex-col items-center justify-center text-white">
          <header className="absolute top-4 left-4">
            <img
              className={"w-10"}
              src="https://res.cloudinary.com/geergregrgege/image/upload/v1722773827/assets/images/usabul_logo_icon_white_bciudc.png"
            />
          </header>
          <main className="flex flex-col items-center">
            <h1 className="text-4xl font-bold mb-2">Welcome to Usabul</h1>
            <p className="text-xl mb-8">Your No.1 User Feedback and QA Tool</p>
            <div className="relative">
              <div className="bg-white text-black p-4 rounded-lg shadow-lg max-w-xs">
                <p className="font-bold mb-2">Bug Report</p>
                <p className="mb-2">
                  I can't submit the form on your home page, the button doesn't
                  work
                </p>
                <span className="bg-blue-100 text-blue-700 px-2 py-1 rounded-full text-xs">
                  Development
                </span>
              </div>
              
            </div>
          </main>
        </div>
      </div>
      <div className={"w-[50%] min-h-full overflow-auto bg-[#e8ecff]"}>
        <Outlet />
      </div>
    </div>
  );
}
