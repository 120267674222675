import { Avatar, Button, Grid, Paper, Chip, Badge } from "@mui/material";
import {
  IconButton,
  Divider,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { findProject } from "../../../slices/project.slice";
import {
  EllipsisHorizontalIcon,
  ExclamationCircleIcon,
} from "@heroicons/react/24/outline";
import services from "../../../services";
import { Archive, Element2, MessageText, Setting2, Trash } from "iconsax-react";
import { enqueueSnackbar } from "notistack";
import moment from "moment";
import clsx from "clsx";
import {
  SiJirasoftware,
  SiGithub,
  SiBitbucket,
  SiNotion,
  SiFigma,
  SiEvernote,
  SiGmail,
  SiSlack,
} from "react-icons/si";

export default function Project(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [project, setProject] = useState(props.project);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    services.accounts.projects.single
      .find({ id: props.project._id })
      .then((response) => {
        setProject(response.data.data.project);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [props.project]);

  return (
    <Grid item={true} xs={12} sm={6} md={4}>
      <div className={"rounded-lg overflow-hidden h-full hover:shadow-md"}>
        <Paper variant={"outlined"} elevation={0} className={" !h-full"}>
          <div
            className={"w-full h-full p-3 border border-gray-200 flex flex-col"}
          >
            <div className={"flex items-center justify-between"}>
              <div className={"flex items-center gap-x-3"}>
                <Badge
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  color={
                    project.integration.type === "none" ? "default" : "info"
                  }
                  overlap="circular"
                  badgeContent={
                    project.integration.type === "jira" ? (
                      <SiJirasoftware />
                    ) : (
                      ""
                    )
                  }
                >
                  <Avatar
                    src={props.project.image}
                    className={"!size-8 !cursor-pointer"}
                    onClick={() => {
                      navigate(`/project/feedback/analytics?id=${project._id}`);
                    }}
                  />
                </Badge>

                <div>
                  <h3
                    className={
                      "font-medium text-md cursor-pointer hover:underline"
                    }
                    onClick={() => {
                      navigate(
                        `/project/feedback/analytics?id=${props.project._id}`,
                      );
                    }}
                  >
                    {props.project.title}
                  </h3>
                  <p
                    className={
                      "text-xs font-light cursor-pointer hover:underline"
                    }
                    onClick={() => {
                      window.open(
                        `https://${project.website}`,
                        "_blank",
                        "noopener,noreferrer",
                      );
                    }}
                  >
                    {props.project.website}
                  </p>
                </div>
              </div>
              <IconButton onClick={handleClick}>
                <EllipsisHorizontalIcon className={"size-5 text-black"} />
              </IconButton>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                className="!px-2"
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                classes={{ paper: "!px-2" }}
              >
                {[
                  {
                    label: "Issues",
                    icon: MessageText,
                    onClick: () => {
                      navigate(
                        `/project/feedback/feedback?id=${props.project._id}`,
                      );
                      handleClose();
                    },
                  },
                  {
                    label: "Widgets",
                    icon: Element2,
                    onClick: () => {
                      navigate(
                        `/project/widget/button?id=${props.project._id}`,
                      );
                      handleClose();
                    },
                  },
                  {
                    label: "Settings",
                    icon: Setting2,
                    onClick: () => {
                      navigate(
                        `/project/settings/general?id=${props.project._id}`,
                      );
                      handleClose();
                    },
                  },
                  {
                    getLabel: function (project) {
                      if (project.isArchived === true) {
                        return "Unarchive";
                      }
                      return "Archive";
                    },
                    icon: Archive,
                    onClick: async () => {
                      handleClose();
                      if (
                        !window.confirm(
                          "Are you sure you want to archive this project?",
                        )
                      ) {
                        return;
                      }
                      const response =
                        await services.accounts.projects.single.update(
                          {
                            id: props.project._id,
                          },
                          {},
                          {
                            isArchived: true,
                            archivedAt: moment().utc(),
                          },
                        );

                      if (response.status === 200) {
                        enqueueSnackbar("Project archived successfully!");
                        navigate("/dashboard");
                      } else {
                        enqueueSnackbar("Failed to archive project.");
                      }
                    },
                  },
                ].map((item, index) => {
                  return (
                    <MenuItem onClick={item.onClick}>
                      <ListItemIcon>
                        <item.icon className="size-5" />
                      </ListItemIcon>
                      <ListItemText>
                        {item.label ? item.label : item.getLabel(props.project)}
                      </ListItemText>
                    </MenuItem>
                  );
                })}
                <MenuItem
                  onClick={async function () {
                    handleClose();
                    if (
                      !window.confirm(
                        "Are you sure you want to delete this project?",
                      )
                    ) {
                      return;
                    }
                    const reponse =
                      await services.accounts.projects.single.update(
                        {
                          id: props.project._id,
                        },
                        {},
                        {
                          isDeleted: true,
                          deletedAt: moment().utc(),
                        },
                      );

                    if (reponse.status === 200) {
                      enqueueSnackbar("Project deleted successfully!");
                      navigate("/dashboard");
                    } else {
                      enqueueSnackbar("Failed to delete project.");
                    }
                  }}
                >
                  <ListItemIcon>
                    <Trash className="size-5 text-red-800" />
                  </ListItemIcon>
                  <ListItemText className="text-red-800">Delete</ListItemText>
                </MenuItem>
              </Menu>
            </div>
            <div
              className={"w-full mt-3 cursor-pointer flex-grow"}
              onClick={() => {
                navigate(`/project/feedback/analytics?id=${props.project._id}`);
              }}
            >
              <img
                alt="usabul"
                src={props.project.previewImage}
                className={"w-full"}
              />
            </div>
            <Divider className={"!my-3"} />
            <div className={"flex gap-x-1"}>
              <Chip
                label={
                  <div className="flex gap-x-1 items-center">
                    <p className={"text-green-700"}>
                      {project.issues?.length || 0}
                    </p>
                    <p>Total</p>
                  </div>
                }
                className={"!bg-[#FFFAC3] !text-green-700"}
              />
              <Chip
                label={
                  <div className="flex gap-x-1 items-center">
                    <p>{project.issues?.length || 0}</p>
                    <p>Open</p>
                  </div>
                }
                className={"!bg-red-100 !text-red-700"}
                onDelete={() => {
                  console.log("test");
                }}
                deleteIcon={
                  <ExclamationCircleIcon className={"size-5 !text-red-700"} />
                }
              />
            </div>
          </div>
        </Paper>
      </div>
    </Grid>
  );
}
