import { useState, useEffect } from "react";
import { Button, OutlinedInput, TextField } from "@mui/material";
import { enqueueSnackbar } from "notistack";

export default function CodeSnippetStepThree() {
  return (
    <div className="w-full py-6 px-5 border-b-gray-300 overflow-x-hidden border-b">
      <div className="flex gap-x-3 w-full overflow-x-hidden">
        <div className="flex gap-x-3 w-[40%] h-max">
          <h1 className="text-2xl font-bold text-blue-200">3</h1>
          <div>
            <p className="text-sm mt-1">Collect advanced data</p>
            <p className="text-xs text-gray-500 font-light mt-2">
              Pass additional information to get the most out of Usabul
            </p>
          </div>
        </div>

        <div className="w-[60%] relative">
          <div className="w-max p-2 border border-blue-400 rounded-md">
            <p className="!text-xs font-light">
              Upgrade to the Team or Enterprise plan to unlock Custom metadata
            </p>
          </div>
          <div className="flex items-end mt-3 gap-x-3">
            <div>
              <TextField
                select={true}
                variant="outlined"
                size="small"
                className="!w-max"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
