import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import services from "../services";

export const findWorkspace = createAsyncThunk(
  "workspace/find",
  async (payload) => {
    return new Promise(async (resolve, reject) => {
      const response = await services.accounts.workspaces.single.find({
        id: payload.parameters.id,
      });
      console.log(response);
      if (response.status === 200) {
        resolve(response.data.data.workspace);
      } else if (response.status === 400) {
        console.log(response.data);
        reject(response.data.message);
      } else {
        payload.navigate("/sign-in");
      }
    });
  },
);

const workspaceSlice = createSlice({
  name: "workspace",
  initialState: {
    data: null,
    status: "idle",
    error: null,
  },
  reducers: {
    setWorkspace: (state, action) => {
      state.data = action.payload;
    },
    clearWorkspace: (state) => {
      state.data = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(findWorkspace.pending, (state) => {
        state.status = "loading";
      })
      .addCase(findWorkspace.fulfilled, (state, action) => {
        state.status = "success";
        state.data = action.payload;
      })
      .addCase(findWorkspace.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error.message;
      });
  },
});

export const { setUser, clearUser } = workspaceSlice.actions;
export default workspaceSlice.reducer;
