import LayoutV1 from "../../layouts/LayoutV1";
import {
  Button,
  ButtonGroup,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import services from "../../services";
import { enqueueSnackbar } from "notistack";
import validator from "validator";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";

export default function () {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState([]);
  const [formData, setFormData] = useState({});

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    errors[3] = "Must contain at least 8 characters";
    errors[4] = "Must contain a number and symbol";
    errors[5] = "Must contain both lowercase and uppercase letters";

    if (formData.password) {
      if (formData.password && formData.password.length > 7) {
        errors[3] = null;
      }
      if (validator.matches(formData.password, /[!@#$%^&*(),.?":{}|<>]/g)) {
        errors[4] = null;
      }
      if (validator.matches(formData.password, /^(?=.*[a-z])(?=.*[A-Z]).+$/g)) {
        errors[5] = null;
      }
    }

    setErrors([...errors]);
  }, [formData]);
  return (
    <div className="bg-none min-h-screen flex flex-col items-center justify-center">
      <div className="bg-none p-8 rounded-lg max-w-[400px] w-full">
        <div className="flex justify-center mb-8">
          <img
            src="https://res.cloudinary.com/geergregrgege/image/upload/v1722772898/assets/images/usabul_logo_blue_bs79du.png"
            alt="Usabul"
            className="h-8"
          />
        </div>
        <ButtonGroup variant={"text"} className={"!w-full"}>
          <Button
            disableElevation={true}
            variant="contained"
            fullWidth={true}
            className={"!bg-blue-700 !font-light !rounded-lg"}
          >
            Sign Up
          </Button>
          <Button
            onClick={() => {
              navigate("/sign-in");
            }}
            fullWidth={true}
            className={"!text-gray-600 !font-light"}
          >
            Sign In
          </Button>
        </ButtonGroup>

        <form className="mt-4">
          <p className={"text-xs mb-1 mt-4"}>First name</p>
          <TextField
            className={"!bg-white"}
            variant="outlined"
            fullWidth={true}
            size="small"
            error={!!errors[1]}
            helperText={errors[1]}
            placeholder="Enter first name"
            onChange={(e) => {
              if (!validator.isLength(e.target.value + "", { min: 3 })) {
                errors[1] = "Must be more than two characters";
                setErrors([...errors]);
              } else {
                errors[1] = null;
                setErrors([...errors]);
              }
              setFormData({ ...formData, firstName: e.target.value });
            }}
          />
          <p className={"text-xs mb-1 mt-4"}>Last name</p>
          <TextField
            className={"!bg-white"}
            size={"small"}
            variant="outlined"
            fullWidth={true}
            error={!!errors[2]}
            helperText={errors[2]}
            placeholder="Enter last name"
            onChange={(e) => {
              if (!validator.isLength(e.target.value + "", { min: 3 })) {
                errors[2] = "Must be more than two characters";
                setErrors([...errors]);
              } else {
                errors[2] = null;
                setErrors([...errors]);
              }
              setFormData({ ...formData, lastName: e.target.value });
            }}
          />

          <p className={"text-xs mb-1 mt-4"}>Email address</p>
          <TextField
            className={"!bg-white"}
            size={"small"}
            error={!!errors[0]}
            variant="outlined"
            fullWidth={true}
            placeholder="Enter work Email"
            helperText={errors[0]}
            onChange={(e) => {
              if (!validator.isEmail(e.target.value + "")) {
                errors[0] = "Must be valid email address";
                setErrors([...errors]);
              } else {
                errors[0] = null;
                setErrors([...errors]);
              }
              setFormData({ ...formData, emailAddress: e.target.value });
            }}
          />

          <p className={"text-xs mb-1 mt-4"}>Password</p>
          <TextField
            className={"!bg-white"}
            size={"small"}
            variant="outlined"
            fullWidth={true}
            type={showPassword ? "text" : "password"}
            placeholder="Enter Password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? (
                      <AiOutlineEye />
                    ) : (
                      <AiOutlineEyeInvisible />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              setFormData({ ...formData, password: e.target.value });
            }}
          />
          <div className="my-4 text-xs">
            <p className="">
              <div className={"flex gap-x-2 items-center mb-3"}>
                <CheckCircleIcon
                  className={clsx("size-4", {
                    "!text-green-500": !errors[3],
                    "!text-gray-400": errors[3],
                  })}
                />
                <p
                  className={clsx({
                    "!text-gray-600": !errors[3],
                    "!text-gray-400": errors[3],
                  })}
                >
                  At least 8 characters
                </p>
              </div>
              <div className={"flex gap-x-2 items-center mb-3"}>
                <CheckCircleIcon
                  className={clsx("size-4", {
                    "!text-green-500": !errors[4],
                    "!text-gray-400": errors[4],
                  })}
                />
                <p
                  className={clsx({
                    "!text-gray-600": !errors[4],
                    "!text-gray-400": errors[4],
                  })}
                >
                  Contains a number and symbol
                </p>
              </div>
              <div className={"flex gap-x-2 items-center mb-3"}>
                <CheckCircleIcon
                  className={clsx("size-4", {
                    "!text-green-500": !errors[5],
                    "!text-gray-400": errors[5],
                  })}
                />
                <p
                  className={clsx({
                    "!text-gray-600": !errors[5],
                    "!text-gray-400": errors[5],
                  })}
                >
                  Contains both uppercase and lowercase letters
                </p>
              </div>
            </p>
          </div>
          <Button
            disableElevation={true}
            variant="contained"
            fullWidth
            className="!bg-blue-700 !text-white !mt-6"
            onClick={async () => {
              const response = await services.accounts.users.signUp({
                ...formData,
              });
              if (response.status === 200) {
                enqueueSnackbar("Success. Proceed to onboarding!");

                sessionStorage.setItem(
                  "access-token",
                  response.data.data.accessToken,
                );
                sessionStorage.setItem("id", response.data.data.user._id);
                navigate("/email-address/verify");
              } else {
                if (response.status === 400) {
                  if (
                    response.data.statusCode === "USEREMAILADDRESSALREADYTAKEN"
                  ) {
                    errors[0] = response.data.message;
                    setErrors([...errors]);
                  } else {
                    enqueueSnackbar(response.data.message);
                  }
                }
              }
            }}
          >
            Create Account
          </Button>
        </form>

        {/*<div className="my-4 flex items-center">*/}
        {/*  <hr className="flex-grow border-t border-gray-300" />*/}
        {/*  <span className="px-2 text-gray-500 text-xs">OR</span>*/}
        {/*  <hr className="flex-grow border-t border-gray-300" />*/}
        {/*</div>*/}
        {/*<div className="flex justify-around mb-4">*/}
        {/*  <Button*/}
        {/*    variant="outlined"*/}
        {/*    className="flex items-center justify-center space-x-2"*/}
        {/*  >*/}
        {/*    /!*<Google/>*!/*/}
        {/*  </Button>*/}
        {/*  <Button*/}
        {/*    variant="outlined"*/}
        {/*    className="flex items-center justify-center space-x-2"*/}
        {/*  >*/}
        {/*    /!*<Apple/>*!/*/}
        {/*  </Button>*/}
        {/*  <Button*/}
        {/*    variant="outlined"*/}
        {/*    className="flex items-center justify-center space-x-2"*/}
        {/*  >*/}
        {/*    /!*<Microsoft/>*!/*/}
        {/*  </Button>*/}
        {/*</div>*/}
        <div className="text-center text-gray-500 text-xs">
          By signing up to create an account I accept Company's <br />
          <a href="#" className="underline">
            Terms of use
          </a>{" "}
          &amp;{" "}
          <a href="#" className="underline">
            Privacy Policy
          </a>
          .
        </div>
      </div>
    </div>
  );
}
