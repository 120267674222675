import NavigationSideBarLeftV2 from "../components/project/NavigationSideBarLeftV2";
import TopBarV1 from "../components/project/TopBarV1";
import { Outlet } from "react-router-dom";

export default function ProjectLayoutV1(props) {
  return (
    <div className={"flex gap-x-6 h-screen w-full bg-[#E8ECFF] px-3"}>
      <div className={"min-w-max h-full overflow-y-auto no-scrollbar"}>
        <div className={"bg-white rounded-xl my-3"}>
          <NavigationSideBarLeftV2 />
        </div>
      </div>
      <div className={"flex-grow h-full overflow-y-auto no-scrollbar"}>
        <div className={"bg-white rounded-xl mt-3"}>
          <TopBarV1 />
        </div>
        <div className={"mt-3 w-full overflow-x-hidden mb-3"}>
          <Outlet />
        </div>
      </div>
    </div>
  );
}
