import axios from "axios";

axios.defaults.validateStatus = function () {
  return true;
};

const baseUrl = process.env.REACT_APP_API_BASE_URL;

const accounts = {
  proxy: function (body) {
    return axios({
      method: "POST",
      url: `${baseUrl}/proxy`,
      data: { ...body },
      headers: {
        "Content-Type": "application/json",
      },
    });
  },
  users: {
    signIn: function (body) {
      return axios({
        method: "POST",
        url: `${baseUrl}/users/sign-in`,
        data: { ...body },
        headers: {
          "Content-Type": "application/json",
        },
      });
    },
    signUp: function (body) {
      return axios({
        method: "POST",
        url: `${baseUrl}/users/sign-up`,
        data: { ...body },
        headers: {
          "Content-Type": "application/json",
        },
      });
    },
    single: {
      find: function (parameters) {
        return axios({
          method: "GET",
          url: `${baseUrl}/users/${parameters.id}/find`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("access-token")}`,
          },
        });
      },
      update: function (parameters, body) {
        return axios({
          method: "POST",
          url: `${baseUrl}/users/${parameters.id}/update`,
          data: {
            ...body,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("access-token")}`,
          },
        });
      },
      emailAddress: {
        verify: function (parameter, query) {
          return axios({
            method: "POST",
            url: `${baseUrl}/users/${parameter.id}/email-address/verify?otp=${query.otp}`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${sessionStorage.getItem("access-token")}`,
            },
          });
        },
      },
      password: {
        update: function (parameters, body) {
          return axios({
            method: "POST",
            url: `${baseUrl}/users/${parameters.id}/password/update`,
            data: {
              ...body,
            },
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${sessionStorage.getItem("access-token")}`,
            },
          });
        },
        reset: function (params, query, body) {
          return axios({
            method: "POST",
            url: `${baseUrl}/users/${params.id}/password/reset?otp=${query.otp}`,
            data: {
              ...body,
            },
            headers: {
              "Content-Type": "application/json",
            },
          });
        },
      },
    },

    otps: {
      create: function (body) {
        return axios({
          method: "POST",
          url: `${baseUrl}/users/otps/create`,
          data: {
            ...body,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("access-token")}`,
          },
        });
      },
    },
  },
  workspaces: {
    single: {
      find: function (parameters) {
        return axios({
          method: "GET",
          url: `${baseUrl}/workspaces/${parameters.id}/find`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("access-token")}`,
          },
        });
      },
      update: function (parameters, headers, body) {
        return axios({
          method: "POST",
          url: `${baseUrl}/workspaces/${parameters.id}/update`,
          data: {
            ...body,
          },
          headers: {
            "Content-Type": "application/json",
            ...headers,
          },
        });
      },
      invitations: {
        create: function (parameters, body) {
          return axios({
            method: "POST",
            url: `${baseUrl}/workspaces/${parameters.id}/invitations/create`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${sessionStorage.getItem("access-token")}`,
            },
            data: body,
          });
        },
      },
    },
    invitations: {
      single: {
        find: (parameters) => {
          return axios({
            method: "GET",
            url: `${baseUrl}/workspaces/invitations/${parameters.id}/find`,
            headers: {
              "Content-Type": "application/json",
            },
          });
        },
        accept: (parameters, body) => {
          return axios({
            method: "POST",
            url: `${baseUrl}/workspaces/invitations/${parameters.id}/accept`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${sessionStorage.getItem("access-token")}`,
            },
            data: body,
          });
        },
      },
    },
  },
  projects: {
    create: function (headers, body) {
      return axios({
        method: "POST",
        url: `${baseUrl}/projects/create`,
        data: {
          ...body,
        },
        headers: {
          "Content-Type": "application/json",
          ...headers,
        },
      });
    },
    single: {
      find: (parameters) => {
        return axios({
          method: "GET",
          url: `${baseUrl}/projects/${parameters.id}/find`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionStorage.getItem("access-token")}`,
          },
        });
      },
      update: function (parameters, headers, body) {
        return axios({
          method: "POST",
          url: `${baseUrl}/projects/${parameters.id}/update`,
          data: {
            ...body,
          },
          headers: {
            "Content-Type": "application/json",
            ...headers,
          },
        });
      },
      issues: {
        metrics: (parameters) => {
          return axios({
            method: "GET",
            url: `${baseUrl}/projects/${parameters.id}/issues/metrics`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${sessionStorage.getItem("access-token")}`,
            },
          });
        },
        find: (parameters) => {
          return axios({
            method: "GET",
            url: `${baseUrl}/projects/${parameters.id}/issues/find`,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${sessionStorage.getItem("access-token")}`,
            },
          });
        },
      },
    },
  },
};

export default accounts;
