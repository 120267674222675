import { Avatar, Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import validator from "validator";
import { useDispatch, useSelector } from "react-redux";
import { findUser } from "../../slices/user.slice";
import clsx from "clsx";

export default function TopBarV1() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const project = useSelector((state) => state.project);
  const [isOpen, setIsOpen] = useState([false]);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (
      sessionStorage.getItem("id") === null ||
      sessionStorage.getItem("access-token") === null
    ) {
      sessionStorage.clear();
      localStorage.clear();
      navigate("/sign-in");
      return;
    }
    if (user.status === "idle") {
      dispatch(
        findUser({
          parameters: {
            id: sessionStorage.getItem("id"),
          },
          navigate,
        }),
      );
    }
  }, []);

  return (
    <>
      <div className={"h-max w-full px-3 py-2 bg-white rounded-md"}>
        <div className={"w-full flex justify-between items-center"}>
          <div className={""}>
            <img
              onClick={() => {
                navigate("/dashboard/home");
              }}
              alt={"usabul"}
              style={{
                height: 24,
                cursor: "pointer"
              }}
              src={
                "https://res.cloudinary.com/geergregrgege/image/upload/v1722772898/assets/images/usabul_logo_blue_bs79du.png"
              }
            />
          </div>
          <div className={"flex items-center gap-x-3"}>
            <div
              onClick={() => {
                navigate("/settings/personal/profile");
              }}
              className={
                "border border-gray-100 hover:bg-gray-100 px-3 rounded-md cursor-pointer"
              }
            >
              <Button
                style={{
                  color: "black",
                }}
                disableRipple={true}
                disableFocusRipple={true}
                startIcon={
                  <Avatar
                    style={{
                      height: 24,
                      width: 24,
                    }}
                    src={user?.data?.image}
                  />
                }
              >
                <p
                  className={"text-sm capitalize"}
                >{`${user?.data?.firstName} ${user?.data?.lastName}`}</p>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
