import services from "../../../../services";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { Dialog, IconButton, TextField, Button } from "@mui/material";
import validator from "validator";
import {
  XMarkIcon,
  BuildingOffice2Icon,
  ServerIcon,
} from "@heroicons/react/24/outline";
import { enqueueSnackbar } from "notistack";

export default function InviteMember(props) {
  const [errors, setErrors] = useState([
    "", // for the email address field
  ]);
  const [formData, setFormData] = useState({});
  const navigate = useNavigate();
  return (
    <Dialog
      open={props.isOpen}
      onClose={props.onClose}
      maxWidth="xs"
      fullWidth
      classes={{ paper: "!rounded-lg !px-4 !pb-7 !pt-6 !w-[350px]" }}
    >
      <div className="flex justify-between items-center">
        <p className="!p-0 !text-md">Project access</p>
        <IconButton onClick={props.onClose} className="!p-0">
          <XMarkIcon className={"size-4"} />
        </IconButton>
      </div>
      <form>
        <div className="flex items-center gap-x-3 mt-3">
          <TextField
            size={"small"}
            fullWidth
            variant="outlined"
            className="bg-white"
            onChange={(e) =>
              setFormData({ ...formData, emailAddress: e.target.value })
            }
          />
          <Button
            disabled={validator.isEmail(formData.emailAddress + "") === false}
            disableElevation={true}
            className={"!bg-blue-700 !text-white"}
            onClick={async () => {
              if (validator.isEmail(formData.emailAddress + "") === false) {
                return;
              }
              const response =
                await services.accounts.workspaces.single.invitations.create(
                  { id: props.workspace.id },
                  {
                    recipient: {
                      emailAddress: formData.emailAddress,
                    },
                  },
                );

              if (response.status !== 200) {
                enqueueSnackbar(
                  response.data?.message || "Something went wrong ...",
                );
                return;
              }
              enqueueSnackbar("Invitation sent successfully!");
              props.onClose();
            }}
          >
            Invite
          </Button>
        </div>
      </form>
    </Dialog>
  );
}
