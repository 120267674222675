import { Divider } from "@mui/material";
import { useEffect, useState } from "react";
import { TwitterPicker } from "react-color";
import { OutlinedInput, Button as Button2 } from "@mui/material";
import services from "../../../../services";
import { useSearchParams } from "react-router-dom";
import { enqueueSnackbar } from "notistack";

export default function Button() {
  const [searchParams] = useSearchParams();
  const [formData, setFormData] = useState({
    backgroundColor: "blue",
    text: "Feedback",
  });
  return (
    <>
      <div>
        <h1 className="text-2xl font-semibold mt-6">Button</h1>
        <p className="text-xs font-light text-gray-600">
          Customize the widget button appearance to match your brand and
          website.
        </p>
      </div>
      <div className="w-full rounded-md mt-6">
        <Divider />
        <div className="flex gap-x-6 mt-6">
          <div className="w-full">
            <p className="text-xs font-inter mt-3">Button color</p>
            <div className="mt-2">
              <TwitterPicker
                triangle="hide"
                color={formData.backgroundColor}
                onChange={(color, event) => {
                  setFormData({ ...formData, backgroundColor: color.hex });
                }}
              />
            </div>
            <p className="text-xs font-inter mt-3">Button text</p>
            <OutlinedInput
              size="small"
              className="!mt-2"
              value={formData.text}
              onChange={(e) => {
                setFormData({ ...formData, text: e.target.value });
              }}
            />
            <div className="mt-6">
              <Button2
                variant="contained"
                className="!bg-blue-700"
                disableElevation={true}
                onClick={async () => {
                  const response =
                    await services.accounts.projects.single.update(
                      { id: searchParams.get("id") },
                      {},
                      {
                        widgets: {
                          button: {
                            ...formData,
                          },
                        },
                      },
                    );

                  if (response.status !== 200) {
                    enqueueSnackbar("Something went wrong!");
                  }
                  enqueueSnackbar("Widget updated successfully");
                }}
              >
                Save Changes
              </Button2>
            </div>
          </div>
          <div className="w-full"></div>
        </div>
      </div>
    </>
  );
}
