import * as prettier from "prettier/standalone";
import parserHtml from "prettier/plugins/html";
import { useState, useEffect } from "react";
import Prism from "prismjs";
import "prismjs/themes/prism.css";
import { Button } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useLocation, useSearchParams } from "react-router-dom";

export default function CodeSnippetStepOne() {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [codeSnippet, setCodeSnippet] = useState(`
  <script>
    window.usabul = {
      project: '${searchParams.get("id")}',
      source: "snippet"
    };
    document.addEventListener("DOMContentLoaded",(()=>{console.log(window.location);const root=document.createElement("div");root.setAttribute("id","usabul-widget-root");document.getElementsByTagName("body")[0].appendChild(root);const style=document.createElement("link");style.setAttribute("rel","stylesheet");style.setAttribute("href","https://cdn.usabul.io/widget.min.css");document.head.appendChild(style);const script=document.createElement("script");script.setAttribute("src","https://cdn.usabul.io/widget.min.js");document.head.appendChild(script)}));
  </script>
  `);

  const [isLoading, setIsLoading] = useState([true]);

  useEffect(() => {
    if (isLoading[0] === false) {
      Prism.highlightAll();
    }
  }, [isLoading]);

  useEffect(() => {
    prettier
      .format(codeSnippet, {
        parser: "html",
        plugins: [parserHtml],
        tabWidth: 8,
        useTabs: false,
        bracketSpacing: true,
      })
      .then((formattedCodeSnippet) => {
        setCodeSnippet(formattedCodeSnippet);
        setIsLoading((isLoading) => {
          isLoading[0] = false;
          return [...isLoading];
        });
      })
      .catch((error) => {
        console.error("something happened when formatting code", error);
      });
  }, []);

  if (isLoading[0] === false) {
    return (
      <div className="w-full py-6 px-5 border-b-gray-300 border-b overflow-x-hidden">
        <div className="flex gap-x-3 w-full overflow-x-hidden">
          <div className="flex gap-x-3 w-[40%] h-max">
            <h1 className="text-2xl font-bold text-blue-200">1</h1>
            <div>
              <p className="text-sm mt-1">Install snippet</p>
              <p className="text-xs text-gray-500 font-light mt-2">
                Insert this code in the{" "}
                <span className="font-normal text-gray-700">{"<Head>"}</span>{" "}
                tag of each page where you want the widget to appear.
              </p>
            </div>
          </div>

          <div className="w-[60%] relative overflow-x-hidden">
            <Button
              size="small"
              variant="contained"
              className="!absolute !right-2 !top-4 !bg-blue-700"
              onClick={async () => {
                await navigator.clipboard.writeText(codeSnippet);
                enqueueSnackbar("Copied to clipboard!");
              }}
            >
              Copy
            </Button>

            <pre className="language-html overflow-x-scroll !text-sm !font-light !bg-none">
              <code className="!text-xs !font-light !bg-none">
                {codeSnippet}
              </code>
            </pre>
          </div>
        </div>
      </div>
    );
  }
}
