import "prismjs/themes/prism-tomorrow.css";

import CodeSnippetStepOne from "../../../../components/project/widget/installation/CodeSnippetStepOne";
import CodeSnippetStepTwo from "../../../../components/project/widget/installation/CodeSnippetStepTwo";
import CodeSnippetStepThree from "../../../../components/project/widget/installation/CodeSnippetStepThree";
import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import services from "../../../../services";

export default function Installation() {
  const [project, setProject] = useState(null);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    async function setup() {
      const response = await services.accounts.projects.single.find({
        id: searchParams.get("id"),
      });
      if (response.status === 200) {
        setProject(response.data.data.project);
      } else {
        return false;
      }
    }

    setup();
  }, []);
  return (
    <>
      <div>
        <h1 className="text-2xl font-semibold mt-6">Installation</h1>
        <p className="text-xs font-light text-gray-600">
          Set up Usabul on your website.
        </p>
      </div>
      <div className="w-full rounded-md bg-white mt-6">
        <CodeSnippetStepOne />
        <CodeSnippetStepTwo project={project} setProject={setProject} />
        <CodeSnippetStepThree />
      </div>
    </>
  );
}
