import { useState, useEffect } from "react";
import { TextField, Button, Typography } from "@mui/material";
import { useSearchParams, useNavigate } from "react-router-dom";
import services from "../../services";
import { enqueueSnackbar } from "notistack";

const Invitation = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState([true]);
  const [invitation, setInvitation] = useState({
    id: searchParams.get("id"),
  });

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    password: "",
  });

  useEffect(() => {
    async function setup() {
      if (searchParams.get("id")) {
        const response =
          await services.accounts.workspaces.invitations.single.find({
            id: searchParams.get("id"),
          });
        if (response.status !== 200) {
          return;
        }
        setInvitation(response.data.data.invitation);
        setIsLoading((isLoading) => {
          isLoading[0] = false;
          return [...isLoading];
        });
      }
    }
    setup();
  }, []);

  if (isLoading[0] === false) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-100">
        <div className="bg-white p-8 rounded-lg shadow-md w-96">
          <div className="text-center mb-6">
            <Typography variant="h5" component="h1" className="font-bold">
              You've been invited to the{" "}
              <span className="text-blue-600">{invitation.workspace.name}</span>{" "}
              workspace.
            </Typography>
            <Typography variant="body2" className="text-gray-500">
              {`Invited by ${invitation.createdBy.firstName} ${invitation.createdBy.lastName}`}
            </Typography>
          </div>

          <form>
            <p className="text-xs font-light mb-1">First Name</p>
            <TextField
              variant="outlined"
              fullWidth
              required
              helperText=""
              onChange={(e) => {
                setFormData({ ...formData, firstName: e.target.value });
              }}
            />
            <p className="text-xs font-light mt-3 mb-1">Last Name</p>
            <TextField
              variant="outlined"
              fullWidth
              required
              helperText=""
              onChange={(e) => {
                setFormData({ ...formData, lastName: e.target.value });
              }}
            />
            <p className="text-xs font-light mt-3 mb-1">Email Address</p>
            <TextField
              type="email"
              variant="outlined"
              fullWidth
              disabled
              required
              helperText=""
              value={invitation.recipient.emailAddress}
            />
            <p className="text-xs font-light mt-3 mb-1">Password</p>
            <TextField
              type="password"
              variant="outlined"
              fullWidth
              required
              helperText=" "
              onChange={(e) => {
                setFormData({ ...formData, password: e.target.value });
              }}
            />

            <Button
              variant="contained"
              color="primary"
              fullWidth
              className="bg-blue-600"
              onClick={async () => {
                const response =
                  await services.accounts.workspaces.invitations.single.accept(
                    { id: searchParams.get("id") },
                    {
                      user: {
                        ...formData,
                      },
                    },
                  );
                if (response.status !== 200) {
                  enqueueSnackbar("Something went wrong!");
                  return;
                }
                enqueueSnackbar(
                  "You've been successfully accepted and joined the workspace! Please sign-in",
                );
                navigate("/sign-in");
              }}
            >
              Accept and Join
            </Button>
          </form>

          <div className="text-center mt-6">
            <Typography variant="body2">
              Already have an account?{" "}
              <p
                onClick={() => {
                  navigate("/sign-in");
                }}
                className="text-blue-600 hover:underline"
              >
                Sign in.
              </p>
            </Typography>
          </div>
        </div>
      </div>
    );
  }
};

export default Invitation;
