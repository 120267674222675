import React, { Fragment, useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";
import services from "../../services";
import {
  Button,
  CircularProgress,
  Collapse,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import ReactOtpInput from "react-otp-input";
import {
  CheckIcon,
  ArrowLongLeftIcon,
  CheckCircleIcon,
} from "@heroicons/react/24/outline";
import validator from "validator";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import clsx from "clsx";

export default function Reset() {
  const [step, setStep] = useState(1);
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    if (
      validator.isMongoId(searchParams.get("user") + "") &&
      validator.isLength(searchParams.get("otp") + "", { min: 6, max: 6 })
    ) {
      setStep(2);
    }
  }, []);
  return (
    <div className="bg-none min-h-screen flex flex-col items-center justify-center">
      <div className="bg-none p-8 rounded-lg max-w-[400px] w-full">
        <div className="flex justify-center mb-8">
          <img
            src="https://res.cloudinary.com/geergregrgege/image/upload/v1722772898/assets/images/usabul_logo_blue_bs79du.png"
            alt="Usabul"
            className="h-7"
          />
        </div>

        <StepOne step={step} setStep={setStep} />
        <StepTwo step={step} setStep={setStep} />

        {/*<Collapse in={step === 2} appear={false} timeout={300}>*/}
        {/*  <Fragment>*/}
        {/*    <p*/}
        {/*      className={*/}
        {/*        "mt-10 text-lg font-semibold text-blue-800 text-center"*/}
        {/*      }*/}
        {/*    >*/}
        {/*      Email Verification*/}
        {/*    </p>*/}
        {/*    <p className={"font-light text-xs text-center"}>*/}
        {/*      Please enter 6-digit code sent to your email address*/}
        {/*    </p>*/}
        {/*    <form className="mt-4">*/}
        {/*      <div className={"w-full flex justify-center"}>*/}
        {/*        <ReactOtpInput*/}
        {/*          value={otp}*/}
        {/*          onChange={(otp) => {*/}
        {/*            setOtp(otp);*/}
        {/*          }}*/}
        {/*          numInputs={6}*/}
        {/*          renderSeparator={<span> </span>}*/}
        {/*          renderInput={(props) => (*/}
        {/*            <input*/}
        {/*              {...props}*/}
        {/*              className={*/}
        {/*                "!size-9 !m-1 border-blue-400 border rounded-md focus:border-blue-500 !outline-0"*/}
        {/*              }*/}
        {/*            />*/}
        {/*          )}*/}
        {/*        />*/}
        {/*      </div>*/}
        {/*      <Button*/}
        {/*        disabled={otp.length < 6}*/}
        {/*        disableElevation={true}*/}
        {/*        onClick={async () => {*/}
        {/*          const response =*/}
        {/*            await services.accounts.users.single.emailAddress.verify(*/}
        {/*              {*/}
        {/*                id: sessionStorage.getItem("id"),*/}
        {/*              },*/}
        {/*              { otp: otp },*/}
        {/*            );*/}
        {/*          if (response.status === 200) {*/}
        {/*            setStep(2);*/}
        {/*          } else {*/}
        {/*            enqueueSnackbar(response.data.message);*/}
        {/*          }*/}
        {/*        }}*/}
        {/*        variant="contained"*/}
        {/*        fullWidth*/}
        {/*        className="!bg-blue-600 !text-white !mt-4 !rounded-md"*/}
        {/*      >*/}
        {/*        Verify*/}
        {/*      </Button>*/}
        {/*    </form>*/}
        {/*    <OtpResend />*/}
        {/*  </Fragment>*/}
        {/*</Collapse>*/}
        {/*<Collapse in={step === 2} timeout={500}>*/}
        {/*  <Fragment>*/}
        {/*    <div className={"w-full px-4 py-6 bg-white rounded-md"}>*/}
        {/*      <div*/}
        {/*        className={"flex items-center justify-center gap-x-3 w-full"}*/}
        {/*      >*/}
        {/*        <div*/}
        {/*          className={*/}
        {/*            "size-7 bg-green-600 rounded-full flex items-center justify-center"*/}
        {/*          }*/}
        {/*        >*/}
        {/*          <CheckIcon className={"!size-4 !text-white"} />*/}
        {/*        </div>*/}
        {/*        <p className={"font-semibold text-md"}>*/}
        {/*          Verification Successful!*/}
        {/*        </p>*/}
        {/*      </div>*/}
        {/*      <Button*/}
        {/*        disableElevation={true}*/}
        {/*        className={"!mt-6 !bg-blue-800 !rounded-md"}*/}
        {/*        variant={"contained"}*/}
        {/*        fullWidth={true}*/}
        {/*        onClick={() => {*/}
        {/*          navigate("/onboard");*/}
        {/*        }}*/}
        {/*      >*/}
        {/*        Proceed to onboarding*/}
        {/*      </Button>*/}
        {/*    </div>*/}
        {/*  </Fragment>*/}
        {/*</Collapse>*/}
      </div>
    </div>
  );
}

function StepOne(props) {
  const navigate = useNavigate();
  const [step, setStep] = useState("1a");

  const [formData, setFormData] = useState({
    emailAddress: "",
  });

  const [errors, setErrors] = useState([]);
  return (
    <Collapse exit={false} in={props.step === 1}>
      <Fragment>
        <Collapse
          exit={false}
          in={props.step === 1 && step === "1a"}
          appear={false}
          timeout={300}
        >
          <Fragment>
            <p className={"mt-10 text-md text-center"}>Forgot Password?</p>
            <p className={"font-light text-xs text-center"}>
              Enter your email address to reset it.
            </p>
            <form className="mt-6">
              <div className={"w-full"}>
                <p className={"text-xs mb-1 mt-4"}>Email address</p>
                <TextField
                  className={"!bg-white"}
                  size={"small"}
                  error={!!errors[0]}
                  variant="outlined"
                  fullWidth={true}
                  placeholder="Enter email address"
                  helperText={errors[0]}
                  onChange={(e) => {
                    if (!validator.isEmail(e.target.value + "")) {
                      errors[0] = "Must be valid email address";
                      setErrors([...errors]);
                    } else {
                      errors[0] = null;
                      setErrors([...errors]);
                    }
                    setFormData({ ...formData, emailAddress: e.target.value });
                  }}
                />
              </div>

              <Button
                disableElevation={true}
                onClick={async () => {
                  const response = await services.accounts.users.otps.create({
                    emailAddress: formData.emailAddress,
                    type: "user_password_reset",
                  });
                  if (response.status === 200) {
                    enqueueSnackbar(
                      `Reset link sent to ${formData.emailAddress}`,
                    );
                    setStep("1b");
                  } else {
                    enqueueSnackbar(response.data.message);
                  }
                }}
                variant="contained"
                fullWidth
                className="!bg-blue-600 !text-white !mt-4 !rounded-md"
              >
                Reset my password
              </Button>
              <Button
                disableElevation={true}
                onClick={() => {
                  navigate("/sign-in");
                }}
                variant="text"
                fullWidth
                className="!text-gray-500 !text-xs !mt-1 hover:underline"
              >
                <div className={"flex w-full justify-between items-center"}>
                  <p></p>
                  <p>Return to sign-in</p>
                  <ArrowLongLeftIcon className={"!size-5 !text-gray-500"} />
                </div>
              </Button>
            </form>
            {/*<OtpResend />*/}
          </Fragment>
        </Collapse>
        <Collapse
          exit={false}
          in={props.step === 1 && step === "1b"}
          timeout={500}
        >
          <Fragment>
            <div className={"w-full px-4 py-6 bg-white rounded-md"}>
              <div
                className={"flex items-center justify-center gap-x-3 w-full"}
              >
                <div
                  className={
                    "size-7 bg-green-600 rounded-full flex items-center justify-center"
                  }
                >
                  <CheckIcon className={"!size-4 !text-white"} />
                </div>
                <p className={"font-semibold text-md"}>Reset link sent!</p>
              </div>

              <Button
                disableElevation={true}
                className={"!mt-6 !bg-blue-800 !rounded-md"}
                variant={"contained"}
                fullWidth={true}
                onClick={() => {
                  navigate("/sign-in");
                }}
              >
                Return to Sign-In
              </Button>
            </div>
          </Fragment>
        </Collapse>
      </Fragment>
    </Collapse>
  );
}

function StepTwo(props) {
  const [searchParams] = useSearchParams();
  const [step, setStep] = useState("2a");
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState([]);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    errors[1] = "Must contain at least 8 characters";
    errors[2] = "Must contain a number and symbol";
    errors[3] = "Must contain both lowercase and uppercase letters";

    if (formData.password) {
      if (formData.password && formData.password.length > 7) {
        errors[1] = null;
      }
      if (validator.matches(formData.password, /[!@#$%^&*(),.?":{}|<>]/g)) {
        errors[2] = null;
      }
      if (validator.matches(formData.password, /^(?=.*[a-z])(?=.*[A-Z]).+$/g)) {
        errors[3] = null;
      }
    }

    if (formData.password === formData.confirmPassword) {
      errors[4] = null;
    } else {
      errors[4] = "Passwords do not match";
    }

    setErrors([...errors]);
  }, [formData]);

  return (
    <Collapse exit={false} appear={false} in={props.step === 2}>
      <Fragment>
        <Collapse in={props.step === 2 && step === "2a"}>
          <form className="mt-4">
            <p className={"text-xs mb-1 mt-4"}>Password</p>
            <TextField
              className={"!bg-white"}
              variant="outlined"
              fullWidth={true}
              type={showPassword ? "text" : "password"}
              placeholder="Enter Password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <AiOutlineEye />
                      ) : (
                        <AiOutlineEyeInvisible />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                setFormData({ ...formData, password: e.target.value });
              }}
            />
            <div className="my-4 text-xs">
              <p className="">
                <div className={"flex gap-x-2 items-center mb-3"}>
                  <CheckCircleIcon
                    className={clsx("size-4", {
                      "!text-green-500": !errors[1],
                      "!text-gray-400": errors[1],
                    })}
                  />
                  <p
                    className={clsx({
                      "!text-gray-600": !errors[1],
                      "!text-gray-400": errors[1],
                    })}
                  >
                    At least 8 characters
                  </p>
                </div>
                <div className={"flex gap-x-2 items-center mb-3"}>
                  <CheckCircleIcon
                    className={clsx("size-4", {
                      "!text-green-500": !errors[2],
                      "!text-gray-400": errors[2],
                    })}
                  />
                  <p
                    className={clsx({
                      "!text-gray-600": !errors[2],
                      "!text-gray-400": errors[2],
                    })}
                  >
                    Contains a number and symbol
                  </p>
                </div>
                <div className={"flex gap-x-2 items-center mb-3"}>
                  <CheckCircleIcon
                    className={clsx("size-4", {
                      "!text-green-500": !errors[3],
                      "!text-gray-400": errors[3],
                    })}
                  />
                  <p
                    className={clsx({
                      "!text-gray-600": !errors[3],
                      "!text-gray-400": errors[3],
                    })}
                  >
                    Contains both uppercase and lowercase letters
                  </p>
                </div>
              </p>
            </div>
            <TextField
              className={"!bg-white"}
              variant="outlined"
              error={!!errors[4]}
              fullWidth={true}
              type={showConfirmPassword ? "text" : "password"}
              placeholder="Enter Password"
              helperText={errors[4]}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                      edge="end"
                    >
                      {showConfirmPassword ? (
                        <AiOutlineEye />
                      ) : (
                        <AiOutlineEyeInvisible />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onChange={(e) => {
                if (formData.password !== formData.confirmPassword) {
                  errors[4] = "Passwords do not match";
                  setErrors([...errors]);
                }
                setFormData({ ...formData, confirmPassword: e.target.value });
              }}
            />
            <Button
              disableElevation={true}
              variant="contained"
              fullWidth
              className="!bg-blue-700 !text-white !mt-6"
              onClick={async () => {
                const response =
                  await services.accounts.users.single.password.reset(
                    {
                      id: searchParams.get("user"),
                    },
                    { otp: searchParams.get("otp") },
                    { newPassword: formData.password },
                  );
                if (response.status === 200) {
                  setStep("2b");
                } else {
                  enqueueSnackbar(response.data.message);
                }
              }}
            >
              Submit
            </Button>
          </form>
        </Collapse>

        <Collapse
          exit={false}
          in={props.step === 2 && step === "2b"}
          timeout={500}
        >
          <Fragment>
            <div className={"w-full px-4 py-6 bg-white rounded-md"}>
              <div
                className={"flex items-center justify-center gap-x-3 w-full"}
              >
                <div
                  className={
                    "size-7 bg-green-600 rounded-full flex items-center justify-center"
                  }
                >
                  <CheckIcon className={"!size-4 !text-white"} />
                </div>
                <p className={"font-semibold text-md"}>
                  Password reset successfully!
                </p>
              </div>

              <Button
                disableElevation={true}
                className={"!mt-6 !bg-blue-800 !rounded-md"}
                variant={"contained"}
                fullWidth={true}
                onClick={() => {
                  navigate("/sign-in");
                }}
              >
                Return to Sign-In
              </Button>
            </div>
          </Fragment>
        </Collapse>
      </Fragment>
    </Collapse>
  );
}
