import { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Avatar,
  IconButton,
  Card,
  CardContent,
  Typography,
  Divider,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import { findUser } from "../../../slices/user.slice";
import { useNavigate } from "react-router-dom";
import services from "../../../services";
import {enqueueSnackbar} from "notistack";

const General = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [formData, setFormData] = useState({
    name: "",
  });

  useEffect(() => {
    if (
      sessionStorage.getItem("id") === null ||
      sessionStorage.getItem("access-token") === null
    ) {
      sessionStorage.clear();
      localStorage.clear();
      navigate("/sign-in");
      return;
    }
    if (user.status === "idle") {
      dispatch(
        findUser({
          parameters: {
            id: sessionStorage.getItem("id"),
          },
          navigate,
        }),
      );
    }
  }, []);

  useEffect(() => {
    console.log(formData);
  }, [formData]);

  return (
    <>
      <div className=" items-center min-h-screen">
        <h1 className="text-2xl">General</h1>
        <div className="w-full mt-10">
          <div className="py-6">
            {/* Basic Details */}
            <Card className="p-4 mb-6 flex justify-between">
              <Typography variant="subtitle1" className="mb-4">
                Basic details
              </Typography>

              <div className="w-[400px] space-y-3">
                <div className="flex flex-col">
                  <Typography variant="text-xs text-gray-600">
                    Workspace Name
                  </Typography>
                  <TextField
                    size={"small"}
                    defaultValue={user.data?.workspace?.name}
                    placeholder={user.data?.workspace?.name}
                    onChange={(e) => {
                      setFormData({ ...formData, name: e.target.value });
                    }}
                  />
                </div>
                <Button
                  disabled={formData.name.length < 3}
                  variant={"contained"}
                  disableElevation={true}
                  onClick={async () => {
                    let body = formData;
                    Object.keys(body).forEach((key, index) => {
                      if (body[key].length < 1) {
                        delete formData[key];
                      }
                    });
                    const response =
                      await services.accounts.workspaces.single.update(
                        {
                          id: user.data.workspace._id,
                        },
                        {},
                        { name: formData.name },
                      );
                    if (response.status !== 200) {
                      enqueueSnackbar(
                        response.data?.message || "Something went wrong!",
                      );
                    }
                    enqueueSnackbar("Workspace updated successfully!");
                  }}
                >
                  Save
                </Button>
              </div>
            </Card>
            <Divider className="mb-4" />
          </div>
        </div>
      </div>
      <input
        accept="image/*"
        id="upload-avatar"
        type="file"
        style={{ display: "none" }}
        onChange={(event) => {
          const file = event.target.files[0];
          if (file) {
            const reader = new FileReader();
            reader.onload = async () => {
              const base64Image = reader.result;
              setFormData({ ...formData, image: base64Image });
              await services.accounts.users.single.update(
                { id: user.data?._id },
                { image: base64Image },
              );
            };
            reader.readAsDataURL(file); // Convert file to Base64
          }
        }}
      />
    </>
  );
};

export default General;
