import { Button, Fade, Collapse, CircularProgress } from "@mui/material";
import validator from "validator";
import services from "../../services";
import { enqueueSnackbar } from "notistack";
import React, { useState, Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ReactOtpInput from "react-otp-input";
import { CheckIcon } from "@heroicons/react/24/outline";

export default function Verify(props) {
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();
  const [step, setStep] = useState(1);

  return (
    <div className="bg-none min-h-screen flex flex-col items-center justify-center">
      <div className="bg-none p-8 rounded-lg max-w-[350px] w-full">
        <div className="flex justify-center mb-8">
          <img
            src="https://res.cloudinary.com/geergregrgege/image/upload/v1722772898/assets/images/usabul_logo_blue_bs79du.png"
            alt="Usabul"
            className="h-7"
          />
        </div>
        <Collapse in={step === 1} appear={false} timeout={300}>
          <Fragment>
            <p
              className={
                "mt-10 text-lg font-semibold text-blue-800 text-center"
              }
            >
              Email Verification
            </p>
            <p className={"font-light text-xs text-center"}>
              Please enter 6-digit code sent to your email address
            </p>
            <form className="mt-4">
              <div className={"w-full flex justify-center"}>
                <ReactOtpInput
                  value={otp}
                  onChange={(otp) => {
                    setOtp(otp);
                  }}
                  numInputs={6}
                  renderSeparator={<span> </span>}
                  renderInput={(props) => (
                    <input
                      {...props}
                      className={
                        "!size-9 !m-1 border-blue-400 border rounded-md focus:border-blue-500 !outline-0"
                      }
                    />
                  )}
                />
              </div>

              <Button
                disabled={otp.length < 6}
                disableElevation={true}
                onClick={async () => {
                  const response =
                    await services.accounts.users.single.emailAddress.verify(
                      {
                        id: sessionStorage.getItem("id"),
                      },
                      { otp: otp },
                    );
                  if (response.status === 200) {
                    setStep(2);
                  } else {
                    enqueueSnackbar(response.data.message);
                  }
                }}
                variant="contained"
                fullWidth
                className="!bg-blue-600 !text-white !mt-4 !rounded-md"
              >
                Verify
              </Button>
            </form>
            <OtpResend />
          </Fragment>
        </Collapse>
        <Collapse in={step === 2} timeout={500}>
          <Fragment>
            <div className={"w-full px-4 py-6 bg-white rounded-md"}>
              <div
                className={"flex items-center justify-center gap-x-3 w-full"}
              >
                <div
                  className={
                    "size-7 bg-green-600 rounded-full flex items-center justify-center"
                  }
                >
                  <CheckIcon className={"!size-4 !text-white"} />
                </div>
                <p className={"font-semibold text-md"}>
                  Verification Successful!
                </p>
              </div>

              <Button
                disableElevation={true}
                className={"!mt-6 !bg-blue-800 !rounded-md"}
                variant={"contained"}
                fullWidth={true}
                onClick={() => {
                  navigate("/onboard");
                }}
              >
                Proceed to onboarding
              </Button>
            </div>
          </Fragment>
        </Collapse>
      </div>
    </div>
  );
}

function OtpResend() {
  const [counter, setCounter] = useState(30); // 30 seconds cooldown
  const [isResending, setIsResending] = useState(false);

  useEffect(() => {
    if (counter > 0) {
      const timer = setTimeout(() => setCounter(counter - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [counter]);

  const handleResendOtp = async () => {
    if (counter > 0) {
      enqueueSnackbar("Resending OTP... Please wait");
      return; // Prevent resending if the cooldown timer is still running
    }
    setIsResending(true);

    const response = await services.accounts.users.otps.create({
      user: sessionStorage.getItem("id"),
      type: "user_email-address_verify",
    });

    if (response.status === 200) {
      enqueueSnackbar("OTP requested successfully!");
    }

    setIsResending(false);
    setCounter(30); // Reset the cooldown timer
  };

  return (
    <div className="mt-3 flex justify-center items-center gap-x-3">
      <p className="text-gray-500 text-xs">Didn’t receive the OTP?</p>
      <p className={"text-xs cursor-pointer"} onClick={handleResendOtp}>
        {isResending ? (
          <CircularProgress size={24} className="text-white" />
        ) : (
          `Resend OTP ${counter > 0 ? `(${counter}s)` : ""}`
        )}
      </p>
    </div>
  );
}
