import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
} from "@mui/material";
import { useNavigate, useMatch } from "react-router-dom";
import {
  UserCircleIcon,
  BuildingOffice2Icon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import clsx from "clsx";
import { useState } from "react";
import {
  Home,
  DirectInbox,
  LogoutCurve,
  ArrowLeft2,
  ArrowRight2,
  User,
} from "iconsax-react";
export default function NavigationSideBarLeftV3(props) {
  const navigate = useNavigate();
  return (
    <div className={"w-full h-full flex flex-col"}>
      <div className={"flex-grow h-full px-3"}>
        <List className={"!mt-3"}>
          {[
            {
              label: "Personal",
              icon: UserCircleIcon,
              path: "/settings/personal/*",
              children: [
                {
                  label: "Profile",
                  path: "/settings/personal/profile",
                },
              ],
            },
            {
              label: "Workspace",
              path: "/settings/workspace/*",
              icon: BuildingOffice2Icon,
              children: [
                {
                  label: "General",
                  path: "/settings/workspace/general",
                },
                {
                  label: "Members",
                  path: "/settings/workspace/members",
                },
              ],
            },
          ].map((item, index) => {
            return <NavigationItem item={item} key={index} />;
          })}
        </List>
      </div>

      {/*<Divider />*/}
      {/*<div className={"px-3"}>*/}
      {/*  <List>*/}
      {/*    <ListItemButton*/}
      {/*      classes={{*/}
      {/*        selected: "!bg-[#0047EA] !text-white",*/}
      {/*      }}*/}
      {/*      selected={false}*/}
      {/*      onClick={() => {*/}
      {/*        navigate("/dashboard/home");*/}
      {/*      }}*/}
      {/*      className={"hover:!bg-gray-100 !rounded-md !font-light"}*/}
      {/*    >*/}
      {/*      <ListItemAvatar>*/}
      {/*        <Avatar*/}
      {/*          className={"!size-5"}*/}
      {/*          src={"https://randomuser.me/api/portraits/men/1.jpg"}*/}
      {/*        />*/}
      {/*      </ListItemAvatar>*/}
      {/*      <ListItemText*/}
      {/*        primaryTypographyProps={{ noWrap: true }}*/}
      {/*        primary={"Ilechukwu Joshua"}*/}
      {/*      />*/}
      {/*    </ListItemButton>*/}
      {/*    <ListItemButton*/}
      {/*      onClick={() => {*/}
      {/*        navigate("/sign-in");*/}
      {/*      }}*/}
      {/*      className={"hover:!bg-red-200 !rounded-md !text-red-700"}*/}
      {/*    >*/}
      {/*      <ListItemIcon>*/}
      {/*        <ArrowLeftStartOnRectangleIcon*/}
      {/*          className={`size-5 text-red-700`}*/}
      {/*        />*/}
      {/*      </ListItemIcon>*/}
      {/*      <ListItemText primary={"Sign Out"} />*/}
      {/*    </ListItemButton>*/}
      {/*  </List>*/}
      {/*</div>*/}
    </div>
  );
}

function NavigationItem(props) {
  const navigate = useNavigate();
  const match = !!useMatch(props.item.path);
  const [isOpen, setIsOpen] = useState(match);
  return (
    <>
      <ListItemButton
        key={props.item.label}
        selected={match}
        onClick={() => {
          if (props.item.children) {
            setIsOpen(!isOpen);
          } else {
            navigate(props.item.path);
          }
        }}
        className={clsx("!rounded-md !mb-3", {
          "!bg-[#0047EA] !text-white": match,
          "!bg-gray-100 !text-gray-500": !match,
        })}
      >
        <ListItemIcon>
          <props.item.icon
            className={clsx("size-5", {
              "!text-white": match,
            })}
          />
        </ListItemIcon>
        <ListItemText
          primary={props.item.label}
          primaryTypographyProps={{
            className: `!text-sm`,
          }}
        />
      </ListItemButton>
      {props.item.children && (
        <List
          disablePadding={true}
          className={"!border-l !border-l-gray-300 !pl-3 !mb-3"}
        >
          {props.item.children.map((child, index) => (
            <NavigationItem2 key={index} item={child} />
          ))}
        </List>
      )}
    </>
  );
}

function NavigationItem2(props) {
  const match = !!useMatch(props.item.path);
  const navigate = useNavigate();
  return (
    <ListItemButton
      selected={match}
      onClick={() => {
        navigate(props.item.path, { replace: true });
      }}
      className={clsx("!rounded-md !mb-0.5", {
        "!bg-[#FFFAC3] !text-black": match,
        "!text-gray-500": !match,
      })}
    >
      <ListItemText
        primary={props.item.label}
        primaryTypographyProps={{
          className: "!text-sm",
        }}
      />
    </ListItemButton>
  );
}
