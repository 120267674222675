import { Checkbox, Divider, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { findUser } from "../../../slices/user.slice";
import { useNavigate } from "react-router-dom";
import services from "../../../services";
import ProjectUsersView from "../../../components/dashboard/users/ProjectUsersView";

export default function Users() {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [issues, setIssues] = useState([]);
  const [selectedIssue, setSelectedIssue] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);

  useEffect(() => {
    if (user.status === "idle") {
      dispatch(
        findUser({
          parameters: {
            id: sessionStorage.getItem("id"),
          },
          navigate,
        }),
      );
    }
    if (user.status === "success") {
      console.log("USER FULFILLED");
      console.log(user.data.projects);
      setProjects(user.data.projects);
    }
  }, [user.status]);

  useEffect(() => {
    async function setup() {
      for (const project of projects) {
        const response = await services.accounts.projects.single.issues.find({
          id: project._id,
        });
        if (response.status === 200) {
          setIssues((issues) => [...issues, ...response.data.data.issues]);
        }
      }
    }
    setup();
  }, [projects]);

  return (
    <div
      className={
        "rounded-xl bg-white h-full border border-gray-300 overflow-hidden"
      }
    >
      <div className={"flex w-full h-full"}>
        <div
          className={
            "bg-neutral-50 w-[50%] h-full overflow-y-auto no-scrollbar"
          }
        >
          <div className={"px-6 py-4"}>
            <p className={"text-lg font-semibold"}>Users</p>
          </div>
          <div className={"px-6"}>
            <TextField variant={"outlined"} size={"small"} fullWidth />
          </div>
          <div className={"px-3 flex items-center justify-between"}>
            <Checkbox size={"small"} />
            <p className={"text-xs font-light"}>0 Items</p>
          </div>
          <div>
            {projects.map((project) => {
              return (
                <>
                  <Divider />
                  <div
                    className={
                      "px-3 py-3 flex justify-between hover:bg-blue-300 cursor-pointer"
                    }
                    onClick={() => {
                      // setSelectedIssue(issue);
                      setSelectedProject(project);
                    }}
                  >
                    <div className={"flex items-center gap-x-2"}>
                      <Checkbox size={"small"} />
                      <div>
                        <p className={"text-sm"}>{project.title}</p>
                        <p className={"text-xs font-light text-gray-500"}>
                          {project.website}
                        </p>
                      </div>
                    </div>
                    <div className={"flex"}>
                      <p className={"text-xs font-light"}>
                        {project.createdBy.emailAddress}
                      </p>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
        <div className={"w-[50%] h-full overflow-y-auto no-scrollbar"}>
          <ProjectUsersView project={selectedProject} />
        </div>
      </div>
    </div>
  );
}
