import React from "react";
import {
  Box,
  Button,
  TextField,
  IconButton,
  Radio,
  RadioGroup,
  FormControlLabel,
  Typography,
  Card,
  CardContent,
  Avatar,
  Divider,
  CircularProgress,
} from "@mui/material";
import { Archive } from "iconsax-react";
import { Delete,   Link, Add } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { findProject } from "../../../slices/project.slice";
import { useSelector, useDispatch } from "react-redux";
import validator from "validator";
import services from "../../../services";
import { enqueueSnackbar } from "notistack";
import moment from "moment/moment";
export default function ProjectSettings() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const project = useSelector((state) => state.project);
  const [formData, setFormData] = useState({});
  const [isLoading, setIsLoading] = useState([
    false, // to check if project icon and name form is submitted
  ]);

  useEffect(() => {
    if (validator.isMongoId(`${query.get("id")}`) === false) {
      sessionStorage.clear();
      localStorage.clear();
      navigate("/sign-in");
    }
    if (project.status === "idle") {
      dispatch(findProject({ parameters: { id: query.get("id") } }));
    }
    console.log(project);
  }, [project.status]);

  return (
    <div className="p-6">
      <h1 className="text-2xl font-semibold">General settings</h1>
      <p className="text-sm font-light text-gray-600 ">
        Manage your project settings.
      </p>
      <Divider className="!my-6" />
      {/* Project Icon and Name Section */}
      <div className="p-3 flex justify-between">
        <h1 className="">Project icon and name</h1>
        <Box className="flex flex-col gap-y-6 min-w-[350px]">
          <div>
            <p className="text-sm">Project Icon</p>
            <p className="text-xs my-1 text-gray-400">
              Upload your website favicon
            </p>
            <Avatar src={project.data?.image} className="cursor-pointer" />
          </div>
          <div>
            <p className="text-sm mb-1">Project Name</p>
            <TextField
              variant="outlined"
              placeholder={project.data?.title}
              value={formData.title || ""}
              className="!w-full !bg-white"
              onChange={(e) => {
                setFormData({ ...formData, title: e.target.value });
              }}
            />
          </div>

          <Button
            disableElevation
            variant="contained"
            className="mt-4 w-max"
            disabled={isLoading[0]}
            onClick={async function () {
              setIsLoading((isLoading) => {
                isLoading[0] = true;
                return [...isLoading];
              });
              const reponse = await services.accounts.projects.single.update(
                {
                  id: query.get("id"),
                },
                {},
                {
                  title: formData.title?.trim() || project.data.title,
                },
              );

              if (reponse.status === 200) {
                enqueueSnackbar("Project updated successfully!");
              } else {
                enqueueSnackbar("Failed to update project.");
              }
              setIsLoading((isLoading) => {
                isLoading[0] = false;
                return [...isLoading];
              });
            }}
          >
            {isLoading[0] === true ? <CircularProgress size={30} /> : "Save"}
          </Button>
        </Box>
      </div>
      <Divider className="!my-6" />
      {/* Website Domains Section */}
      <div className="p-3">
        <Typography variant="h6" className="text-gray-600 mb-4">
          Website domains
        </Typography>
        <Box className="flex items-center space-x-2">
          <Button startIcon={<Link />} className="bg-gray-100 text-blue-500">
            {`https://${project.data?.website}`}
          </Button>
          <IconButton>
            <Add />
          </IconButton>
        </Box>
      </div>
      <Divider className="!my-6" />
      {/* Thumbnail Section */}
      <div className="p-4 flex justify-between">
        <Typography variant="h6" className="text-gray-600 mb-4">
          Thumbnail
        </Typography>
        <div className="space-y-6 min-w-[350px]">
          <Box className="flex p-3 justify-center items-center bg-gray-200 h-48">
            <Typography className="text-gray-500">
              Recommended size: 1920x1080, Ratio: 16:9
            </Typography>
          </Box>
          <Button variant="contained" className="mt-4">
            Save
          </Button>{" "}
        </div>
      </div>
      <Divider className="!my-6" />
      {/* Feedback Visibility Section */}
      <Card className="p-4">
        <Typography variant="h6" className="text-gray-600 mb-4">
          Feedback visibility
        </Typography>
        <RadioGroup defaultValue="guest">
          <FormControlLabel
            value="guest"
            control={<Radio />}
            label="Guests can view and comment feedback from other Guests"
          />
          <FormControlLabel
            value="noGuest"
            control={<Radio />}
            label="Guests can't see feedback from Members"
          />
        </RadioGroup>
      </Card>

      {/* Comments Section */}
      <Card className="p-4">
        <Typography variant="h6" className="text-gray-600 mb-4">
          Comments
        </Typography>
        <RadioGroup defaultValue="resolved">
          <FormControlLabel
            value="resolved"
            control={<Radio />}
            label="Users can post new comments when feedback is Resolved or Archived"
          />
        </RadioGroup>
      </Card>

      {/* Archive and Delete Project Section */}
      <Card className="p-4">
        <Box className="flex justify-evenly gap-x-6">
          <div className="w-full">
            <Typography variant="h6" className="text-gray-600 mb-4">
              Archive Project
            </Typography>
            <p className="text-xs text-gray-400 my-3">
              Disables feedback reporting and removes the project from the
              dashboard. You can restore it later.
            </p>
            <Button  startIcon={<Archive className="size-4" />} variant="outlined" className="!text-gray-600">
              Archive Project
            </Button>
          </div>

          <div className="w-full">
            <Typography variant="h6" className="text-gray-600 mb-4">
              Delete Project
            </Typography>
            <p className="text-xs text-gray-400 my-3">
              Permanently deletes this project along with all its feedback and
              settings.
            </p>
            <Button
              disableElevation
              startIcon={<Delete />}
              variant="contained"
              color="error"
              onClick={async function () {
                setIsLoading((isLoading) => {
                  isLoading[0] = true;
                  return [...isLoading];
                });
                const reponse = await services.accounts.projects.single.update(
                  {
                    id: query.get("id"),
                  },
                  {},
                  {
                    isDeleted: true,
                    deletedAt: moment().utc()
                  },
                );

                if (reponse.status === 200) {
                  enqueueSnackbar("Project deleted successfully!");
                  navigate("/dashboard");
                } else {
                  enqueueSnackbar("Failed to delete project.");
                }
                setIsLoading((isLoading) => {
                  isLoading[0] = false;
                  return [...isLoading];
                });
              }}
            >
              Delete Project
            </Button>
          </div>
        </Box>
      </Card>
    </div>
  );
}
