import services from "../../services";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import {
  Dialog,
  IconButton,
  TextField,
  Button,
  Avatar,
  Switch,
  styled,
} from "@mui/material";
import { XMarkIcon, BuildingOffice2Icon } from "@heroicons/react/24/outline";
import { enqueueSnackbar } from "notistack";

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#1890ff",
        ...theme.applyStyles("dark", {
          backgroundColor: "#177ddc",
        }),
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: "rgba(0,0,0,.25)",
    boxSizing: "border-box",
    ...theme.applyStyles("dark", {
      backgroundColor: "rgba(255,255,255,.35)",
    }),
  },
}));

export default function InviteProjectMember(props) {
  const [formData, setFormData] = useState({});
  const navigate = useNavigate();
  return (
    <Dialog
      open={props.isOpen}
      onClose={props.handleClose}
      maxWidth="xs"
      fullWidth
      classes={{ paper: "!rounded-lg !px-4 !pb-7 !pt-6 !w-[350px]" }}
    >
      <div className="flex justify-between items-center">
        <p className="!p-0 !text-md">Project access</p>
        <IconButton onClick={props.handleClose} className="!p-0">
          <XMarkIcon className={"size-4"} />
        </IconButton>
      </div>
      <form>
        <div className="flex gap-x-3 mt-3">
          <TextField
            size={"small"}
            fullWidth
            variant="outlined"
            className="bg-white"
            onChange={(e) =>
              setFormData({ ...formData, title: e.target.value })
            }
          />
          <Button
            disableElevation={true}
            className={"!bg-blue-700 !text-white"}
          >
            Invite
          </Button>
        </div>
      </form>
      <div className="flex justify-between items-center mt-3">
        <p className="!p-0 !text-sm">Groups</p>
      </div>
      <div className="flex items-center justify-between mt-3">
        <div className="flex items-center gap-x-3">
          <Avatar className="!bg-white border">
            <BuildingOffice2Icon className={"!size-4 !text-black"} />
          </Avatar>
          <div>
            <p className="text-xs font-light">Workspace members</p>
            <p className="text-xs font-light text-gray-500">1 member</p>
          </div>
        </div>
        <AntSwitch />
      </div>
    </Dialog>
  );
}
