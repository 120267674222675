import { useState } from "react";

export default function CustomHorizontalConnector(props) {
  const [numberOfDots, serNumberOfDots] = useState(5);
  return (
    <div className={"flex flex-col gap-1"}>
      {[...Array(numberOfDots)].map((item, index) => {
        return <div key={index} className="h-[5px] w-[2px] bg-gray-300"></div>;
      })}
    </div>
  );
}
