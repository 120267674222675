import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import services from "../services";

// Asynchronous thunk to fetch user data
export const findUser = createAsyncThunk("user/find", async (payload) => {
  return new Promise(async (resolve, reject) => {
    const response = await services.accounts.users.single.find({
      id: payload.parameters.id,
    });
    console.log(response);
    if (response.status === 200) {
      resolve(response.data.data.user);
    } else if (response.status === 400) {
      if (response.data.message === "User not found") {
        payload.navigate("/sign-in");
      } else {
        console.log(response.data);
      }
      reject(response.data.message);
    } else {
      payload.navigate("/sign-in");
    }
  });
});

const userSlice = createSlice({
  name: "user",
  initialState: {
    data: null,
    status: "idle",
    error: null,
  },
  reducers: {
    setUser: (state, action) => {
      state.data = action.payload;
    },
    clearUser: (state) => {
      state.data = null;
    },
  },
  // middleware: (getDefaultMiddleware) => {
  //   getDefaultMiddleware().concat((storeApi) => (next) => (action) => {
  //     const result = next(action);
  //     const currentUrl = window.location.href;
  //     const pathname = window.location.pathname;
  //
  //     console.log("Current URL:", currentUrl);
  //     console.log("Pathname:", pathname);
  //
  //     storeApi.dispatch(findUser({ id: sessionStorage.getItem("id") }));
  //
  //     return result;
  //   });
  // },
  extraReducers: (builder) => {
    builder
      .addCase(findUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(findUser.fulfilled, (state, action) => {
        state.status = "success";
        state.data = action.payload;
      })
      .addCase(findUser.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error.message;
      });
  },
});

export const { setUser, clearUser } = userSlice.actions;
export default userSlice.reducer;
