import { Avatar, Badge, Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import validator from "validator";
import { findProject } from "../../slices/project.slice";
import { useSelector, useDispatch } from "react-redux";
import InviteProjectMember from "./InviteProjectMember";
import { SiJirasoftware } from "react-icons/si";

export default function TopBarV1() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const project = useSelector((state) => state.project);
  const [isOpen, setIsOpen] = useState([false]);

  useEffect(() => {
    if (validator.isMongoId(`${query.get("id")}`) === false) {
      sessionStorage.clear();
      localStorage.clear();
      navigate("/sign-in");
    }

    dispatch(findProject({ parameters: { id: query.get("id") } }));
  }, [location]);

  return (
    <>
      <div className={"h-max w-full px-6 py-2"}>
        <div className={"w-full flex justify-between items-center"}>
          <div className={"flex items-center gap-x-3"}>
            <Badge
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              color={
                project?.data?.integration?.type === "none" ? "default" : "info"
              }
              overlap="circular"
              badgeContent={
                project?.data?.integration?.type === "jira" ? <SiJirasoftware /> : ""
              }
            >
              <Avatar className={"!h-8 !w-8"} src={project?.data?.image} />
            </Badge>
            <div className={"h-max"}>
              <h1 className={"font-semibold"}>{project?.data?.title}</h1>
              <p className={"text-xs text-gray-400"}>
                {project?.data?.website}
              </p>
            </div>
          </div>
          <div className={"flex items-center gap-x-3"}>
            <Avatar
              className={"!h-8 !w-8"}
              src={"https://randomuser.me/api/portraits/men/1.jpg"}
            />
            <Button
              disableElevation={true}
              size="small"
              variant={"contained"}
              className={"!bg-blue-800"}
              onClick={() => {
                setIsOpen((isOpen) => {
                  isOpen[0] = true;
                  return [...isOpen];
                });
              }}
            >
              Invite
            </Button>
          </div>
        </div>
      </div>
      <InviteProjectMember
        isOpen={isOpen[0]}
        handleClose={() => {
          setIsOpen((isOpen) => {
            isOpen[0] = false;
            return [...isOpen];
          });
        }}
      />
    </>
  );
}
