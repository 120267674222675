import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import services from "../services";

export const findProject = createAsyncThunk("project/find", async (payload) => {
  return new Promise(async (resolve, reject) => {
    const response = await services.accounts.projects.single.find({
      id: payload.parameters.id,
    });
    console.log(response);
    if (response.status === 200) {
      resolve(response.data.data.project);
    } else if (response.status === 400) {
      console.log(response.data);
      reject(response.data.message);
    } else {
      sessionStorage.clear();
      localStorage.clear();
      payload.navigate("/sign-in");
    }
  });
});

const projectSlice = createSlice({
  name: "project",
  initialState: {
    data: null,
    status: "idle",
    error: null,
  },
  reducers: {
    setProject: (state, action) => {
      state.data = action.payload;
    },
    clearProject: (state) => {
      state.data = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(findProject.pending, (state) => {
        state.status = "loading";
      })
      .addCase(findProject.fulfilled, (state, action) => {
        state.status = "success";
        state.data = action.payload;
      })
      .addCase(findProject.rejected, (state, action) => {
        state.status = "error";
        state.error = action.error.message;
      });
  },
});

export const { setProject, clearProject } = projectSlice.actions;
export default projectSlice.reducer;
