import { Checkbox, Divider, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { findUser } from "../../../../slices/user.slice";
import { useNavigate, useSearchParams } from "react-router-dom";
import services from "../../../../services";
import IssueView from "../../../../components/dashboard/inbox/IssueView";

export default function Feedbacks() {
  const user = useSelector((state) => state.user);
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [issues, setIssues] = useState([]);
  const [selectedIssue, setSelectedIssue] = useState(null);
  const [isLoading, setIsLoading] = useState([true]);

  useEffect(() => {
    if (user.status === "idle") {
      dispatch(
        findUser({
          parameters: {
            id: sessionStorage.getItem("id"),
          },
          navigate,
        }),
      );
    }
    if (user.status === "success") {
      console.log("USER FULFILLED");
    }
  }, [user.status]);

  useEffect(() => {
    async function setup() {
      const response = await services.accounts.projects.single.issues.find({
        id: searchParams.get("id"),
      });
      if (response.status === 200) {
        if (response.data.data.issues[0]) {
          setSelectedIssue(response.data.data.issues[0]);
        }
        setIssues((issues) => [...response.data.data.issues]);
      }

      setIsLoading((isLoading) => {
        setIsLoading[0] = false;
        return [...isLoading];
      });
    }

    setup();
  }, []);

  useEffect(() => {
    console.log(selectedIssue);
  }, [selectedIssue]);

  return (
    <>
      <div>
        <h1 className="text-2xl font-semibold mt-6">Feedbacks</h1>
        <p className="text-xs font-light mt-3 text-gray-600">
          Get some insight on your reporting
        </p>
      </div>
      {issues.length > 0 && (
        <div
          className={
            "bg-white h-full border border-gray-300 overflow-hidden mt-6"
          }
        >
          <div className={"flex w-full h-full"}>
            <div
              className={
                "bg-neutral-50 w-full max-w-[400px] h-full overflow-y-auto no-scrollbar"
              }
            >
              <div className={"px-6 py-3 flex items-center justify-between"}>
                <div></div>
                <p
                  className={"text-xs font-light"}
                >{`${issues.length} Items`}</p>
              </div>
              <div className={"px-0"}>
                {issues.map((issue) => {
                  return (
                    <>
                      <Divider />
                      <div
                        className={
                          "px-6 py-3 flex justify-between hover:bg-gray-200 cursor-pointer"
                        }
                        onClick={() => {
                          setSelectedIssue(issue);
                        }}
                      >
                        <div className={"flex items-center gap-x-2"}>
                          <div>
                            <p className={"text-xs font-light text-gray-500"}>
                              {issue.project.title}
                            </p>
                            <p className={"text-sm"}>{issue.summary}</p>
                          </div>
                        </div>
                        <div className={"flex"}>
                          <p className={"text-xs font-light"}>
                            {issue.createdBy.emailAddress}
                          </p>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
            <div className={"w-full h-full overflow-y-auto no-scrollbar"}>
              <IssueView issue={selectedIssue} />
            </div>
          </div>
        </div>
      )}
    </>
  );
}
