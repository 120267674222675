import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Dialog,
  Grid,
  IconButton,
  TextField,
  MenuItem,
  InputAdornment,
} from "@mui/material";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Add, FolderCross } from "iconsax-react";
import services from "../../../services";
import { enqueueSnackbar } from "notistack";
import {
  SiJira,
  SiGithub,
  SiBitbucket,
  SiNotion,
  SiFigma,
  SiEvernote,
  SiGmail,
  SiSlack,
} from "react-icons/si";
import validator from "validator";
import axios from "axios";

export default function CreateProjectDialog(props) {
  const [formData, setFormData] = useState({
    integration: {
      type: "none",
    },
  });
  const navigate = useNavigate();
  const [view, setView] = useState("default");
  return (
    <Dialog
      open={props.isOpen}
      onClose={() => {
        setView("default");
        props.onClose();
      }}
      maxWidth="xs"
      fullWidth
      classes={{
        paper: "!rounded-lg !px-6 !pb-7 !pt-3 !max-w-[350px] no-scrollbar",
      }}
      TransitionProps={{
        unmountOnExit: true,
      }}
    >
      <div className="flex justify-between items-center">
        <h2 className="!p-0 text-center text-lg">
          {view === "default"
            ? "Create new project"
            : "Where do you want to collect issues?"}
        </h2>
        <IconButton
          onClick={() => {
            setView("default");
            props.onClose();
          }}
        >
          <XMarkIcon className={"size-4"} />
        </IconButton>
      </div>
      {view === "default" && (
        <form>
          <div className="mb-4">
            <p className={"text-xs mb-1 mt-4"}>Name</p>
            <TextField
              size={"small"}
              fullWidth
              value={formData.title || ""}
              variant="outlined"
              placeholder="My project"
              className="bg-white"
              onChange={(e) =>
                setFormData({ ...formData, title: e.target.value })
              }
            />
          </div>
          <div className="mb-4">
            <p className={"text-xs mb-1 mt-4"}>Website</p>
            <TextField
              size={"small"}
              fullWidth
              value={formData.website || ""}
              variant="outlined"
              placeholder="example.com, www.example.com ..."
              className={"bg-white"}
              onChange={(e) =>
                setFormData({ ...formData, website: e.target.value })
              }
            />
          </div>
          <div className="mb-4">
            <p className={"text-xs mb-1 mt-4"}>Integration</p>
            <div
              className={
                "h-[40px] p-1 border border-gray-400 rounded-sm pl-2 flex items-center justify-between"
              }
            >
              <div className={"flex gap-x-2 items-center"}>
                <p className={"text-gray-400 text-sm"}>
                  {formData.integration?.type === "none" && "No Integration"}
                  {formData.integration?.type === "jira" && "Jira"}
                </p>
              </div>

              <Button
                startIcon={<Add className={"size-4"} />}
                variant="text"
                size={"small"}
                color="primary"
                className="!text-xs !text-gray-800 !font-light !h-full"
                onClick={() => {
                  setFormData(() => {
                    return {
                      ...formData,
                      integration: {
                        type: "none",
                      },
                    };
                  });
                  setView("integration");
                }}
              >
                {formData.integration?.type !== "none"
                  ? "Change Integration"
                  : "Add Integration"}
              </Button>
            </div>
          </div>
          {formData.integration?.type === "jira" && (
            <JiraIntegrationSetup
              formData={formData}
              setFormData={setFormData}
            />
          )}
          <Button
            disabled={
              validator.isLength(formData.title + "", { min: 3 }) === false ||
              validator.isURL(formData.website + "") === false
            }
            size={"large"}
            disableElevation={true}
            variant="contained"
            fullWidth
            classes={{
              root: "!bg-blue-700 !text-white !font-light",
              disabled: "!bg-gray-400 !cursor-not-allowed",
            }}
            onClick={async () => {
              const response = await services.accounts.projects.create(
                {
                  Authorization: `Bearer ${sessionStorage.getItem("access-token")}`,
                },
                {
                  ...formData,
                  workspace: props.workspace.data._id,
                },
              );

              if (response.status === 200) {
                enqueueSnackbar("Success!");
                setFormData({
                  integration: {
                    type: "none",
                  },
                });
                props.setIsOpen(false);
              } else {
                if (response.status === 400) {
                  if (response.data.statusCode === "WORKSPACE_NOT_FOUND") {
                    navigate("/sign-in");
                  }
                } else {
                  enqueueSnackbar(
                    "An error occurred while creating the project.",
                  );
                }
              }
            }}
          >
            Create Project
          </Button>
        </form>
      )}
      {view === "integration" && (
        <div className={"mt-3"}>
          <Grid container={"true"} spacing={1}>
            {[
              {
                icon: SiJira,
                label: "Jira",
                onClick: () => {
                  setFormData((formData) => {
                    return {
                      ...formData,
                      integration: {
                        type: "jira",
                        url: "https://example.com",
                      },
                    };
                  });
                  setView("default");
                },
              },
              // {
              //   icon: SiGithub,
              //   label: "Gitbub",
              // },
              // {
              //   icon: SiBitbucket,
              //   label: "Bitbucket",
              // },
              // {
              //   icon: SiNotion,
              //   label: "Notion",
              // },
              // {
              //   icon: SiSlack,
              //   label: "Slack",
              // },
              // {
              //   icon: SiGmail,
              //   label: "Gmail",
              // },
              // {
              //   icon: SiFigma,
              //   label: "Figma",
              // },
              // {
              //   icon: SiEvernote,
              //   label: "Evernote",
              // },
            ].map((item, index) => {
              return (
                <Grid item={true} xs={4}>
                  <div
                    onClick={item.onClick}
                    className={
                      "p-3 !bg-white !flex !flex-col !items-center !justify-center rounded-sm !gap-2 border !border-gray-200 hover:border-2 cursor-pointer"
                    }
                  >
                    <item.icon className={"size-8"} />
                    <p>{item.label}</p>
                  </div>
                </Grid>
              );
            })}
          </Grid>
          <div className={"flex justify-center mt-6"}>
            <Button
              className={"!bg-blue-700 !text-white w-full"}
              onClick={() => {
                setFormData(() => {
                  return {
                    ...formData,
                    integration: {
                      type: "none",
                    },
                  };
                });
                setView("default");
              }}
            >
              Continue without integration
            </Button>
          </div>
        </div>
      )}
    </Dialog>
  );
}

function JiraIntegrationSetup({ formData, setFormData }) {
  const [projects, setProjects] = useState([]);
  const [isOpen, setIsOpen] = useState([false]);
  useEffect(() => {
    console.log(formData.integration);
    async function setup() {
      if (
        validator.isURL(formData.integration.url + "") === true &&
        validator.isEmail(formData.integration.emailAddress + "") === true &&
        validator.isLength(formData.integration.apiKey + "", { min: 12 }) ===
          true
      ) {
        try {
          const config = {
            method: "GET",
            url: formData.integration.url + "/rest/api/3/project/recent",
            headers: { "Content-Type": "application/json" },
            auth: {
              username: formData.integration.emailAddress,
              password: formData.integration.apiKey,
            },
          };
          const response = await services.accounts.proxy(config);
          console.log(response.data);
          setProjects(response.data);
          return response.data;
        } catch (error) {
          console.log("error: ");
        }
      }
    }
    setup();
  }, [formData]);
  return (
    <>
      <div>
        <p className={"text-xs mb-1 mt-4"}>Jira URL</p>
        <TextField
          size={"small"}
          fullWidth
          variant="outlined"
          placeholder="example"
          className={"bg-white"}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">https://</InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="start">.atlassian.net</InputAdornment>
            ),
          }}
          onChange={(e) =>
            setFormData({
              ...formData,
              integration: {
                ...formData.integration,
                type: "jira",
                url: `https://${e.target.value}.atlassian.net`,
              },
            })
          }
        />
        <p className={"text-xs mt-2"}>
          Please ensure that your Jira URL is valid and accessible.
        </p>
      </div>
      <div>
        <p className={"text-xs mb-1 mt-4"}>Jira Email address</p>
        <TextField
          size={"small"}
          fullWidth
          type={"email"}
          helperText=" "
          variant="outlined"
          placeholder="me@organization.com"
          className={"bg-white"}
          onChange={(e) =>
            setFormData({
              ...formData,
              integration: {
                ...formData.integration,
                type: "jira",
                emailAddress: e.target.value,
              },
            })
          }
        />
      </div>
      <div>
        <p className={"text-xs mb-1 mt-1"}>Jira API Key</p>
        <TextField
          size={"small"}
          fullWidth
          type={"password"}
          helperText=" "
          variant="outlined"
          placeholder="XXXXXXXXX"
          className={"bg-white"}
          onChange={(e) =>
            setFormData({
              ...formData,
              integration: {
                ...formData.integration,
                type: "jira",
                apiKey: e.target.value,
              },
            })
          }
        />
      </div>
      <div>
        <p className={"text-xs mb-1 mt-1"}>Jira Project</p>
        <TextField
          size={"small"}
          fullWidth
          select={true}
          helperText=" "
          variant="outlined"
          placeholder="XXXXXXXXX"
          defaultValue={" "}
          className={"bg-white"}
          onChange={(e) =>
            setFormData({
              ...formData,
              integration: {
                ...formData.integration,
                type: "jira",
                project: e.target.value,
              },
            })
          }
        >
          <MenuItem className={"!hidden"} value={" "}>
            Select Project
          </MenuItem>
          {projects.length === 0 && (
            <MenuItem disabled={true}>No projects to show.</MenuItem>
          )}
          {projects.map((project) => (
            <MenuItem key={project.id} value={project.id}>
              {project.name}
            </MenuItem>
          ))}
        </TextField>
      </div>
    </>
  );
}
