import { Button, Divider, IconButton } from "@mui/material";
import { useEffect, useState } from "react";
import services from "../../../../services";
import { useSearchParams } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import {
  Arrow,
  ArrowDown,
  ArrowDown2,
  ArrowDown3,
  ArrowUp,
  ArrowUp2,
  ArrowUp3,
} from "iconsax-react";

function moveItem(array, index, direction) {
  // Determine the target index based on the direction
  const targetIndex = direction === "up" ? index - 1 : index + 1;
  
  // Check if the move is valid
  if (targetIndex < 0 || targetIndex >= array.length) {
    console.warn("Move is out of bounds");
    return array; // Return the array unchanged
  }
  
  // Swap the items
  const newArray = [...array]; // Create a copy to avoid mutating the original array
  [newArray[index], newArray[targetIndex]] = [
    newArray[targetIndex],
    newArray[index],
  ];
  
  return newArray;
}

export default function MemberForm() {
  const [project, setProject] = useState(null);
  const [searchParams] = useSearchParams();
  const [formData, setFormData] = useState({
    backgroundColor: "blue",
    text: "Feedback",
  });
  
  const [fields, setFields] = useState([]);
  
  useEffect(() => {
    async function setup() {
      const response = await services.accounts.projects.single.find({
        id: searchParams.get("id"),
      });
      if (response.status === 200) {
        setProject(response.data.data.project);
        setFields(response.data.data.project.widgets.guestForm.fields);
      } else {
        return false;
      }
      if (response.data.data.project.widgets.guestForm.fields.length < 1) {
        if (response.data.data.project.integration.type === "jira") {
          setFields([
            {
              label: "Summary",
              type: "issue_summary",
              required: true,
            },
            {
              label: "Description",
              type: "issue_description",
              required: false,
            },
            {
              label: "Type",
              type: "jira_issue_type",
              required: false,
            },
            {
              label: "Priority",
              type: "jira_issue_priority",
              required: false,
            },
            {
              label: "Reporter",
              type: "jira_issue_reporter",
              required: false,
            },
            {
              label: "Assignee",
              type: "jira_issue_assignee",
              required: false,
            },
            {
              label: "Start Date",
              type: "jira_issue_start_date",
              required: false,
            },
            {
              label: "Due Date",
              type: "jira_issue_due_date",
              required: false,
            },
          ]);
        }
        if (response.data.data.project.integration.type === "none") {
          setFields([
            {
              label: "Summary",
              type: "issue_summary",
              required: true,
            },
            {
              label: "Description",
              type: "issue_description",
              required: false,
            },
            {
              label: "Type",
              type: "issue_type",
              required: false,
            },
            {
              label: "Priority",
              type: "issue_priority",
              required: false,
            },
            {
              label: "Due Date",
              type: "issue_due_date",
              required: false,
            },
          ]);
        }
      }
    }
    
    setup();
  }, []);
  return (
    <>
      <div>
        <h1 className="text-2xl font-semibold mt-6">Guest Form</h1>
        <p className="text-xs font-light text-gray-600">
          For Guests and public reporters. Best for UAT and collecting issues
          from clients.
        </p>
      </div>
      <div className="w-full rounded-md mt-6">
        <Divider />
        <div className={"w-full flex justify-end mt-6"}>
          <Button
            variant={"contained"}
            disableElevation={true}
            onClick={async () => {
              await services.accounts.projects.single.update(
                {
                  id: searchParams.get("id"),
                },
                {},
                {
                  widgets: {
                    guestForm: {
                      fields: fields,
                    },
                  },
                },
              );
              enqueueSnackbar("Form saved successfully", {
                variant: "success",
              });
            }}
          >
            Save Changes
          </Button>
        </div>
        <div className={"flex justify-center w-full mt-6"}>
          <div className={"py-8 px-4 bg-gray-100"}>
            {fields.map((field, index) => {
              return (
                <div className={"flex gap-x-1"}>
                  <div
                    style={{ minWidth: 200 }}
                    key={index}
                    className={"bg-white p-3 my-1"}
                  >
                    <p
                      className={"text-sm"}
                    >{`${field.label}${field.required ? "*" : ""}`}</p>
                  </div>
                  <div className={"flex items-center"}>
                    {index > 0 && (
                      <IconButton
                        disableRipple={true}
                        size={"small"}
                        className={""}
                        onClick={() => {
                          setFields(moveItem(fields, index, "up"));
                        }}
                      >
                        <ArrowUp3 />
                      </IconButton>
                    )}
                    {index < fields.length - 1 && (
                      <IconButton
                        disableRipple={true}
                        size={"small"}
                        className={""}
                        onClick={() => {
                          setFields(moveItem(fields, index, "down"));
                        }}
                      >
                        <ArrowDown3 />
                      </IconButton>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}
