import { Button, Card, TextField, Typography } from "@mui/material";
import services from "../../../../services";
import { useState } from "react";
import { enqueueSnackbar } from "notistack";
import validator from "validator";

export default function UpdatePassword(props) {
  const [formData, setFormData] = useState({});
  return (
    <Card className="p-4 mb-6 flex justify-between">
      <Typography variant="subtitle1" className="mb-4">
        Password
      </Typography>

      <div className="w-[400px] flex items-center space-x-4">
        {/*<LockIcon className="text-gray-500" />*/}
        {/*<Typography variant="body1" className="text-gray-500">*/}
        {/*  You don't have any password yet*/}
        {/*</Typography>*/}
        {/*<Button variant="text" color="primary">*/}
        {/*  Click to set password*/}
        {/*</Button>*/}
        <div className="w-[400px] space-y-3">
          <div className="flex flex-col">
            <Typography variant="text-xs text-gray-600">Password</Typography>
            <TextField
              type={"password"}
              value={formData.password || ""}
              size={"small"}
              onChange={(e) => {
                setFormData({ ...formData, password: e.target.value });
              }}
            />
          </div>
          <div className="flex flex-col">
            <Typography variant="text-xs text-gray-600">
              New Password
            </Typography>
            <TextField
              value={formData.newPassword || ""}
              type={"password"}
              size={"small"}
              onChange={(e) => {
                setFormData({ ...formData, newPassword: e.target.value });
              }}
            />
          </div>
          <div className="flex flex-col">
            <Typography variant="text-xs text-gray-600">
              Confirm New Password
            </Typography>
            <TextField
              value={formData.newPasswordConfirmation || ""}
              type={"password"}
              size={"small"}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  newPasswordConfirmation: e.target.value,
                });
              }}
            />
          </div>
          <Button
            disabled={
              !formData.password ||
              !formData.newPassword ||
              !formData.newPasswordConfirmation ||
              formData.newPassword !== formData.newPasswordConfirmation ||
              validator.isStrongPassword(formData.newPassword + "") === false
            }
            variant={"contained"}
            disableElevation={true}
            onClick={async () => {
              let body = formData;
              Object.keys(body).forEach((key, index) => {
                if (body[key].length < 1) {
                  delete formData[key];
                }
              });

              const response =
                await services.accounts.users.single.password.update(
                  {
                    id: props.user.data._id,
                  },
                  body,
                );

              if (response.status !== 200) {
                enqueueSnackbar(
                  response.data?.message || "Something went wrong!!!",
                );
              } else {
                setFormData({});
                enqueueSnackbar("Password updated successfully!");
              }
            }}
          >
            Save
          </Button>
        </div>
      </div>
    </Card>
  );
}
