import { useState, useEffect } from "react";
import { Avatar, Button, Chip, Collapse, TextField } from "@mui/material";
import clsx from "clsx";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

export default function IssueView(props) {
  const [isLoading, setIsLoading] = useState([true]);
  useEffect(() => {
    if (props.project === null) {
    } else {
      setIsLoading((isLoading) => {
        isLoading[0] = false;
        return [...isLoading];
      });
    }
  }, [props.project]);
  return (
    <div className={"w-full"}>
      {isLoading[0] === false && (
        <>
          <div className={"px-6 py-4"}>
            <p className={"text-lg font-semibold"}>{props.project.title}</p>
            <p className={"text-xs !text-blue-700"}>{`10 users`}</p>
            <div className="flex justify-end gap-x-3">
              <TextField
                size="small"
                placeholder="Search"
                className="!rounded-full"
              />
              <Button
                disableElevation={true}
                size="small"
                variant="contained"
                className="!bg-blue-700 !text-white"
              >
                Search
              </Button>
            </div>
            <div className="mt-3">
              <FeedbackTable />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

function FeedbackTable() {
  const data = [
     
  ];

  return (
    <TableContainer>
      <Table>
        <TableHead className="bg-gray-100">
          <TableRow>
            <TableCell className="font-bold">Email</TableCell>
            <TableCell className="font-bold">First feedback</TableCell>
            <TableCell className="font-bold">Last feedback</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow key={index}>
              <TableCell className="text-blue-600">{row.email}</TableCell>
              <TableCell>{row.firstFeedback}</TableCell>
              <TableCell>{row.secondFeedback}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
