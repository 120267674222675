import LayoutV1 from "../../layouts/LayoutV1";
import services from "../../services";

import React from "react";
import {
  TextField,
  Button,
  InputAdornment,
  IconButton,
  ButtonGroup,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import validator from "validator";
import { enqueueSnackbar } from "notistack";

export default function SignIn(props) {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState([]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div className="bg-none min-h-screen flex flex-col items-center justify-center">
      <div className="bg-none p-8 rounded-lg max-w-[400px] w-full">
        <div className="flex justify-center mb-8">
          <img
            src="https://res.cloudinary.com/geergregrgege/image/upload/v1722772898/assets/images/usabul_logo_blue_bs79du.png"
            alt="Usabul"
            className="h-8"
          />
        </div>
        <ButtonGroup variant={"text"} className={"!w-full !bg-none"}>
          <Button
            onClick={() => {
              navigate("/sign-up");
            }}
            fullWidth={true}
            className={"!text-gray-600 !font-light"}
          >
            Sign Up
          </Button>
          <Button
            disableElevation={true}
            fullWidth={true}
            variant="contained"
            className={"!bg-blue-700 !text-white !font-light !rounded-lg"}
          >
            Sign In
          </Button>
        </ButtonGroup>

        <form className="mt-4">
          <p className={"text-xs mb-1 mt-4"}>Email address</p>
          <TextField
            variant="outlined"
            fullWidth={true}
            size={"small"}
            className={"!bg-white"}
            placeholder="Enter work Email"
            error={!!errors[0]}
            helperText={errors[0]}
            onChange={(e) => {
              if (!validator.isEmail(e.target.value + "")) {
                errors[0] = "Must be valid email address";
                setErrors([...errors]);
              } else {
                errors[0] = null;
                setErrors([...errors]);
              }
              setFormData({ ...formData, emailAddress: e.target.value });
            }}
          />

          <p className={"text-xs mb-1 mt-4"}>Password</p>
          <TextField
            className={"!bg-white"}
            variant="outlined"
            fullWidth={true}
            size={"small"}
            type={showPassword ? "text" : "password"}
            placeholder="Enter Password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? (
                      <AiOutlineEye />
                    ) : (
                      <AiOutlineEyeInvisible />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              setFormData({ ...formData, password: e.target.value });
            }}
          />
          <div className="text-xs text-right mb-2">
            <p
              onClick={() => {
                navigate("/password/reset");
              }}
              className="text-gray-500 mt-1.5 cursor-pointer hover:underline"
            >
              Forgot Password?
            </p>
          </div>
          <Button
            onClick={async () => {
              window.addEventListener("message", (event) => {
                console.log("Message from iframe ", event);
              });
              const response = await services.accounts.users.signIn({
                ...formData,
              });
              if (response.status === 200) {
                sessionStorage.setItem(
                  "access-token",
                  response.data.data.accessToken,
                );
                sessionStorage.setItem("id", response.data.data.user._id);
                console.log(response.data.data.user.projects);
                // for (const project of response.data.data.user.projects) {
                //   console.log(project);
                //   const iframe = document.createElement("iframe");
                //   if (validator.contains(project.website, "localhost")) {
                //     iframe.src = `http://${project.website}`;
                //   } else {
                //     iframe.src = `https://${project.website}`;
                //   }
                //   iframe.src = `https://${project.website}`;
                //   iframe.width = "600";
                //   iframe.height = "400";
                //   iframe.sandbox = "allow-same-origin allow-scripts";
                //   iframe.referrerPolicy = "no-referrer"
                //   document.body.appendChild(iframe);
                //
                //   iframe.addEventListener("load", () => {
                //     console.log("Iframe has loaded, ready to postMessage.");
                //     iframe.contentWindow.postMessage(
                //       JSON.stringify({accessToken: response.data.data.accessToken}),
                //       `https://${project.website}`,
                //     );
                //   });
                // }
                navigate("/dashboard/home");
              } else {
                enqueueSnackbar(response.data?.message);
              }
            }}
            variant="contained"
            fullWidth
            disableElevation={true}
            className="!bg-blue-700 !text-white !mt-6"
          >
            Sign In
          </Button>
        </form>

        {/*<div className="my-4 flex items-center">*/}
        {/*  <hr className="flex-grow border-t border-gray-300" />*/}
        {/*  <span className="px-2 text-gray-500 text-xs">OR</span>*/}
        {/*  <hr className="flex-grow border-t border-gray-300" />*/}
        {/*</div>*/}
        {/*<div className="flex justify-around mb-4">*/}
        {/*  <Button*/}
        {/*    variant="outlined"*/}
        {/*    className="flex items-center justify-center space-x-2"*/}
        {/*  >*/}
        {/*    /!*<Google/>*!/*/}
        {/*  </Button>*/}
        {/*  <Button*/}
        {/*    variant="outlined"*/}
        {/*    className="flex items-center justify-center space-x-2"*/}
        {/*  >*/}
        {/*    /!*<Apple/>*!/*/}
        {/*  </Button>*/}
        {/*  <Button*/}
        {/*    variant="outlined"*/}
        {/*    className="flex items-center justify-center space-x-2"*/}
        {/*  >*/}
        {/*    /!*<Microsoft/>*!/*/}
        {/*  </Button>*/}
        {/*</div>*/}
        <div className="text-center text-gray-500 text-xs">
          By signing up to create an account I accept Company's <br />
          <a href="#" className="underline">
            Terms of use
          </a>{" "}
          &amp;{" "}
          <a href="#" className="underline">
            Privacy Policy
          </a>
          .
        </div>
      </div>
    </div>
  );
}
