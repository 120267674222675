import NavigationSideBarLeftV3 from "../components/NavigationSideBarLeftV3";
import TopBarV1 from "../components/settings/TopBarV1";
import { Outlet } from "react-router-dom";

export default function SettingsLayoutV1(props) {
  return (
    <div className={"flex flex-col gap-6 min-h-screen w-full bg-[#E8ECFF] p-4"}>
      <TopBarV1 />
      <div className={"max-w-screen-xl w-full mx-auto flex gap-6"}>
        <div className={"w-[18%] h-full bg-white rounded-md"}>
          <NavigationSideBarLeftV3 />
        </div>
        <div className={"flex-grow h-full"}>
          <Outlet />
        </div>
      </div>
    </div>
  );
}
