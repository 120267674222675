import { Avatar, Button, Grid, Paper, Chip } from "@mui/material";
import {
  Dialog,
  DialogTitle,
  IconButton,
  TextField,
  ButtonGroup,
  Divider,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { findUser } from "../../../slices/user.slice";
import { findWorkspace } from "../../../slices/workspace.slice";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import services from "../../../services";
import { enqueueSnackbar } from "notistack";
import clsx from "clsx";
import Project from "../../../components/dashboard/home/Project";
import CreateProjectDialog from "../../../components/dashboard/home/CreateProject";
import { Add } from "iconsax-react";

export default function Home() {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const workspace = useSelector((state) => state.workspace);
  const dispatch = useDispatch();
  const [projects, setProjects] = useState([]);

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    function setup() {
      if (sessionStorage.getItem("id") === null) {
        navigate("/sign-in");
        return;
      }

      dispatch(
        findUser({
          parameters: {
            id: sessionStorage.getItem("id"),
          },
          navigate,
        }),
      );

      if (user.status === "success") {
        setProjects(() => {
          return [...user.data.projects];
        });
        dispatch(
          findWorkspace({
            parameters: {
              id: user.data.workspace._id,
            },
            navigate,
          }),
        );
      }
    }
    setup();
    setTimeout(() => {
      setup();
    }, 2000);
  }, [isOpen]);
  return (
    <div className={"w-full h-full pt-6"}>
      {user?.data?.projects?.length < 1 ? (
        <NoProjectsPrompt user={user} isOpen={isOpen} setIsOpen={setIsOpen} />
      ) : (
        <Projects
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          workspace={workspace}
          projects={user.data?.projects || []}
        />
      )}

      <CreateProjectDialog
        workspace={workspace}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onClose={() => {
          setIsOpen(false);
        }}
      />
    </div>
  );
}

//https://res.cloudinary.com/geergregrgege/image/upload/v1723566781/assets/images/Frame_1000003850_l50wjg.png

function Projects(props) {
  const navigate = useNavigate();
  const [filters, setFilters] = useState({ isArchived: false });
  const [projects, setProjects] = useState(props.projects || []);

  useEffect(() => {
    setProjects((projects) => {
      projects = props.projects.filter(
        (project) => project.isArchived === filters.isArchived,
      );
      return [...projects];
    });
  }, [filters, props.projects]);

  return (
    <div className={"w-full h-full"}>
      <div className={"flex justify-between items-center"}>
        <ButtonGroup
          variant={"text"}
          className={"!bg-blue-200 !p-1 !rounded-lg"}
        >
          <Button
            disableRipple={true}
            size={"small"}
            className={clsx("!text-black !px-3 !rounded-lg", {
              "!bg-white": !filters.isArchived,
            })}
            onClick={() => {
              setFilters({ ...filters, isArchived: false });
            }}
          >
            Active
          </Button>
          <Button
            disableRipple={true}
            size={"small"}
            className={clsx("!text-black !px-3 !rounded-lg", {
              "!bg-white": filters.isArchived,
            })}
            onClick={() => {
              setFilters({ ...filters, isArchived: true });
            }}
          >
            Archived
          </Button>
        </ButtonGroup>
        <TextField
          variant={"outlined"}
          size={"small"}
          placeholder={"Search"}
          className={"!bg-white !rounded-lg !h-full"}
          InputProps={{
            startAdornment: <MagnifyingGlassIcon className={"size-4 me-3"} />,
          }}
        />
      </div>
      <div className={"w-full flex justify-between items-center mt-3"}>
        <p className={"text-lg"}>Projects</p>
        <Button
          disableElevation={true}
          variant={"contained"}
          className={"!bg-blue-700 !font-light"}
          onClick={() => {
            props.setIsOpen(true);
          }}
          startIcon={<Add />}
        >
          Create New Project
        </Button>
      </div>
      <div className={"mt-3"}>
        {projects.length > 0 ? (
          <Grid container={true} spacing={3}>
            {projects.map((project, index) => (
              <Project project={project} key={index} />
            ))}
          </Grid>
        ) : (
          <p>Nothing to see here ...</p>
        )}
      </div>
    </div>
  );
}

function NoProjectsPrompt(props) {
  return (
    <div
      className={
        "mx-auto w-full max-w-[300px] h-full flex flex-col items-center justify-center"
      }
    >
      <Avatar
        src={
          "https://res.cloudinary.com/geergregrgege/image/upload/v1722773653/assets/images/usabul_logo_icon_blue_zbtfze.png"
        }
      />

      <h2
        className={"mt-6 font-medium text-xl"}
      >{`Welcome, ${props.user?.data?.firstName}`}</h2>
      <div className={"rounded-xl bg-white shadow-sm p-6 w-full mt-6"}>
        <img
          src={
            "https://res.cloudinary.com/geergregrgege/image/upload/v1722965361/assets/images/usabul_no_project_default_anle9e.png"
          }
        />
        <p className={"text-center mt-4 font-medium"}>Bug Report</p>
        <p className={"text-center text-gray-500 mt-3 font-light text-xs"}>
          Start your first project by selecting this template
        </p>
        <div className={"flex justify-center mt-6"}>
          <Button
            disableElevation={true}
            onClick={() => {
              props.setIsOpen(true);
            }}
            variant={"contained"}
            className={"!bg-blue-900"}
          >
            Continue
          </Button>
        </div>
      </div>
    </div>
  );
}
