import NavigationSideBarLeftV1 from "../components/dashboard/NavigationSideBarLeftV1";
import TopBarV1 from "../components/dashboard/TopBarV1";
import { Outlet } from "react-router-dom";

export default function DasboardLayoutV1(props) {
  return (
    <div className={"min-h-screen bg-[#E8ECFF]"}>
      <TopBarV1 />
      <div
        className={
          "overflow-x-visible md:gap-x-6 w-full  p-1 sm:p-2 md:p-4"
        }
      >
        <div
          className={
            "flex-grow no-scrollbar overflow-y-auto max-w-screen-xl mx-auto"
          }
        >
          <Outlet />
        </div>
      </div>
    </div>
  );
}
