import { useState, useEffect } from "react";
import { Button, OutlinedInput, CircularProgress } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { Flashy, Verify } from "iconsax-react";

export default function CodeSnippetStepTwo(props) {
  const [isLoading, setIsLoading] = useState([false]);
  return (
    <div className="w-full py-6 px-5 border-b-gray-300 overflow-x-hidden border-b">
      <div className="flex gap-x-3 w-full overflow-x-hidden">
        <div className="flex gap-x-3 w-[40%] h-max">
          <h1 className="text-2xl font-bold text-blue-200">2</h1>
          <div>
            <p className="text-sm mt-1">Verify installation</p>
          </div>
        </div>

        <div className="w-[60%] relative">
          <div className="w-full p-3 border border-blue-400 rounded-md flex gap-x-3 items-center">
            {props.project?.widgets?.isVerified === true ? (
              <>
                <Verify />
                <p className="text-sm">Your installation has been verified!</p>
              </>
            ) : (
              <>
                <Flashy className={"text-blue-500"} />
                <p className="text-sm">
                  Your installation has not been verified
                </p>
              </>
            )}
          </div>
          <div className="flex items-end mt-3 gap-x-3">
            <div>
              <p className="text-xs font-light text-gray-600 mb-2">Website</p>
              <OutlinedInput
                variant="outlined"
                size="small"
                className="!w-max"
                disabled={true}
                value={`https://${props.project?.website}`}
              />
            </div>
            <Button
              disabled={props.project?.widgets?.isVerified === true}
              onClick={() => {
                setIsLoading((isLoading) => {
                  isLoading[0] = true;
                  return [...isLoading];
                });
                // Create an iframe
                const iframe = document.createElement("iframe");
                iframe.src = `https://${props.project.website}`; // Replace with the desired URL
                iframe.style.display = "none"; // Hide the iframe
                iframe.id = "secret-iframe"; // Assign an ID for easy removal

                // Append the iframe to the document body
                document.body.appendChild(iframe);

                // Remove the iframe after 5 seconds
                setTimeout(() => {
                  const iframeToRemove =
                    document.getElementById("secret-iframe");
                  if (iframeToRemove) {
                    document.body.removeChild(iframeToRemove);
                  }
                  setIsLoading((isLoading) => {
                    isLoading[0] = false;
                    return [...isLoading];
                  });
                  window.location.reload();
                }, 5000); // 5000ms = 5 seconds
              }}
              variant="outlined"
              size="small"
              className="!py-2"
            >
              {isLoading[0] === true ? <CircularProgress size={5} /> : "Verify"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
