import { useState, useEffect, useRef } from "react";
import {
  Avatar,
  Button,
  Chip,
  Collapse,
  Tab,
  Tabs,
  Divider,
} from "@mui/material";
import clsx from "clsx";
import { ArrowRotateLeft, Image, VideoPlay } from "iconsax-react";
import Player from "rrweb-player";
import "rrweb-player/dist/style.css";
import { Replayer } from "rrweb";

export default function IssueView(props) {
  const [isLoading, setIsLoading] = useState([true]);
  const [screenCaptureType, setScreenCaptureType] = useState("image");
  const [view, setView] = useState("issue");
  const [view2, setView2] = useState("all");
  const [view3, setView3] = useState("screen_capture");
  const [logs, setLogs] = useState([]);
  useEffect(() => {
    if (props.issue === null) {
    } else {
      setIsLoading((isLoading) => {
        isLoading[0] = false;
        return [...isLoading];
      });
    }
    setScreenCaptureType("image");
    setLogs(props.issue?.logs ? props.issue.logs : []);
  }, [props.issue]);

  return (
    <div className={"w-full"}>
      {isLoading[0] === false && (
        <>
          <div className={"px-6 py-4"}>
            <p className={"text-lg font-semibold"}>{props.issue?.summary}</p>
            <p className={"text-xs !text-blue-700"}>
              {props.issue?.createdBy?.emailAddress}
            </p>
            <p className={"mt-6 text-sm text-gray-500"}>
              {props.issue?.description}
            </p>
            <div className={"mt-6"}>
              {view3 === "screen_capture" && (
                <>
                  {screenCaptureType === "image" && (
                    <img
                      src={props.issue?.screenCapture?.image}
                      alt="issue-screen-capture"
                    />
                  )}
                  {screenCaptureType === "video" && (
                    <video className="w-full max-w-full" controls>
                      <source
                        key={props.issue?.screenCapture?.video}
                        src={props.issue?.screenCapture?.video}
                        type="video/webm"
                      />
                      Your browser does not support the video tag.
                    </video>
                  )}
                </>
              )}
              {view3 === "session_replay" && (
                <>
                  <SessionReplay events={props.issue?.session?.events} />
                </>
              )}

              <div className={"mt-6 flex justify-center"}>
                <div className={"p-1"}>
                  <Button
                    disableElevation={true}
                    variant={"contained"}
                    onClick={() => {
                      setView3("screen_capture");
                      setScreenCaptureType("image");
                    }}
                  >
                    <Image />
                  </Button>
                </div>
                {props.issue?.screenCapture?.video?.length > 5 && (
                  <div className={"p-1"}>
                    <Button
                      disableElevation={true}
                      variant={"contained"}
                      onClick={() => {
                        setView3("screen_capture");
                        setScreenCaptureType("video");
                      }}
                    >
                      <VideoPlay />
                    </Button>
                  </div>
                )}
                {props.issue?.session?.events?.length > 0 && (
                  <div className={"p-1"}>
                    <Button
                      disableElevation={true}
                      variant={"contained"}
                      onClick={() => {
                        setView3("session_replay");
                      }}
                    >
                      <ArrowRotateLeft />
                    </Button>
                  </div>
                )}
              </div>
            </div>
            <div className={"mt-6 border-b-1 border-b-gray-500"}>
              <Tabs
                fullWidth={true}
                value={view}
                onChange={(event, value) => {
                  setView(value);
                }}
              >
                <Tab label="Issue" value={"issue"} />
                <Tab label="Developer Tools" value={"developer_tools"} />
              </Tabs>
            </div>
            {view === "issue" && (
              <table className={"mt-6"}>
                <tbody>
                  <tr>
                    <td>
                      <p
                        className={"text-sm font-semibold text-gray-700 w-max"}
                      >
                        Reported by:
                      </p>
                    </td>
                    <td className={"p-2"}>
                      <p
                        className={"text-sm text-gray-600"}
                      >{`${props.issue?.createdBy?.firstName} ${props.issue?.createdBy?.lastName} (${props.issue?.createdBy?.emailAddress})`}</p>
                    </td>
                  </tr>
                  <tr>
                    <td className={"w-max"}>
                      <p
                        className={
                          "text-sm font-semibold text-gray-700 w-full text-right"
                        }
                      >
                        Source URL:
                      </p>
                    </td>
                    <td className={"p-2"}>
                      <p
                        className={"text-sm text-gray-600"}
                      >{`${props.issue?.url.protocol}//${props.issue?.project.website}${props.issue?.url.path}`}</p>
                    </td>
                  </tr>
                  <tr>
                    <td className={"w-max"}>
                      <p
                        className={
                          "text-sm font-semibold text-gray-700 w-full text-right"
                        }
                      >
                        Device:
                      </p>
                    </td>
                    <td className={"p-2"}>
                      <div
                        className={
                          "bg-gray-200 rounded-md p-3 border border-gray-800"
                        }
                      >
                        <p
                          className={"text-sm text-gray-600 font-mono"}
                        >{`Viewport: ${props.issue?.device.viewport.width} * ${props.issue?.device.viewport.height}`}</p>
                        <p
                          className={"text-sm text-gray-600 font-mono"}
                        >{`User-Agent: ${props.issue?.device.userAgent}`}</p>
                        <p
                          className={"text-sm text-gray-600 font-mono"}
                        >{`IP: ${props.issue?.device.ipAddress}`}</p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
            {view === "developer_tools" && (
              <div className={"mt-6"}>
                <div className={"flex gap-x-2 mb-3 px-3"}>
                  {[
                    {
                      label: "Debug",
                      onClick: () => {
                        setLogs(
                          props.issue.logs.filter(
                            (item) => item.type === "debug",
                          ),
                        );
                      },
                    },
                    {
                      label: "Warn",
                      onClick: () => {
                        setLogs(
                          props.issue.logs.filter(
                            (item) => item.type === "warn",
                          ),
                        );
                      },
                    },
                    {
                      label: "Log",
                      onClick: () => {
                        setLogs(
                          props.issue.logs.filter(
                            (item) => item.type === "log",
                          ),
                        );
                      },
                    },
                    {
                      label: "Error",
                      onClick: () => {
                        setLogs(
                          props.issue.logs.filter(
                            (item) => item.type === "error",
                          ),
                        );
                      },
                    },
                  ].map((item, index) => {
                    return (
                      <p
                        onClick={item.onClick}
                        className={"text-sm hover:underline cursor-pointer"}
                      >
                        {item.label}
                      </p>
                    );
                  })}
                </div>
                {logs.map((log, index) => {
                  return (
                    <>
                      <div
                        key={index}
                        className={clsx("py-1 px-3", {
                          "bg-gray-200": log.type === "log",
                          "bg-amber-200": log.type === "warn",
                          "bg-red-200": log.type === "error",
                          "bg-green-100": log.type === "debug",
                        })}
                      >
                        <p className={"text-xs text-gray-600"}>
                          {log.message.join(" ")}
                        </p>
                      </div>
                      <Divider className={"!my-0.5"} />
                    </>
                  );
                })}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}

function SessionReplay({ events }) {
  const replayerRef = useRef(null); // Reference to the DOM container
  const playerInstance = useRef(null); // Reference to the Replayer instance
  useEffect(() => {
    if (playerInstance.current === null) {
      const player = new Player({
        target: replayerRef.current,
        props: {
          events: events,
          showController: false,
        },
      });

      playerInstance.current = player;
    }

    return () => {};
  }, []);

  // useEffect(() => {
  //   if (replayerRef.current && events && events.length) {
  //     // Clean up any previous instance
  //     if (playerInstance.current) {
  //       playerInstance.current.pause();
  //       playerInstance.current = null;
  //     }
  //
  //     // Initialize the Replayer
  //     playerInstance.current = new Replayer(events, {
  //       target: replayerRef.current, // Target container for the replayer
  //       mouseTail: true, // Show mouse trail
  //       UNSAFE_replayCanvas: true, // Allow canvas recording replay (optional)
  //     });
  //
  //     playerInstance.current.play(); // Start playback
  //   }
  //
  //   return () => {
  //     // Cleanup on component unmount
  //     if (playerInstance.current) {
  //       playerInstance.current.pause();
  //       playerInstance.current = null;
  //     }
  //   };
  // }, [events]);

  return (
    <div
      ref={replayerRef}
      style={{ width: "max-content", height: "600px" }}
      className={"mx-auto"}
    ></div>
  );
}
