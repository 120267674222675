import { VictoryAxis, VictoryChart, VictoryLine, VictoryTheme } from "victory";
import { ResponsiveLine } from "@nivo/line";
import _ from "lodash";
import { useEffect } from "react";

export default function IssueChart(props) {
  useEffect(() => {
    console.log("test");
  }, []);
  return (
    <div className={"h-[400px]"}>
      <VictoryChart theme={VictoryTheme.clean}>
        <VictoryAxis
          tickValues={_.range(2010, 2024, 2)}
          style={{
            tickLabels: {
              fontSize: 8,
            },
            ticks: {
              stroke: "#757575",
              size: 5,
            },
          }}
        />
        <VictoryAxis
          dependentAxis
          //tickValues={_.range(0, 200, 40)}
          tickFormat={(value) => `${value.toFixed(2)}`}
          style={{
            axis: {
              stroke: "transparent",
            },
            axisLabel: {
              fontSize: 8,
              padding: 50,
            },
            tickLabels: {
              fontSize: 8,
            },
            grid: {
              stroke: "#d9d9d9",
              size: 5,
            },
          }}
        />
        <VictoryLine data={props.data} />
      </VictoryChart>
    </div>
  );
}
