import React, { useEffect, useState } from "react";
import {
  Slide,
  Button,
  Collapse,
  Fade,
  TextField,
  CircularProgress,
} from "@mui/material";
import services from "../../services";

import { enqueueSnackbar } from "notistack";

import LayoutV2 from "../../layouts/LayoutV2";
import CustomSelectV1 from "../../components/CustomSelectV1";
import CustomHorizontalConnector from "../../components/CustomHorizontalConnector";
import { useNavigate } from "react-router-dom";
import { CheckIcon } from "@heroicons/react/24/outline";

import { useDispatch, useSelector } from "react-redux";
import { findUser } from "../../slices/user.slice";

export default function Onboard() {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);

  return (
    <LayoutV2>
      <div
        className={
          "!h-screen flex flex-col items-center justify-center relative"
        }
      >
        <Collapse
          in={step === 1}
          appear={false}
          exit={false}
          timeout={400}
          mountOnEnter={true}
          unmountOnExit={true}
        >
          <StepOne step={step} setStep={setStep} />
        </Collapse>
        <Collapse
          in={step === 2}
          timeout={400}
          exit={false}
          mountOnEnter={true}
          unmountOnExit={true}
        >
          <StepTwo step={step} setStep={setStep} />
        </Collapse>
        <Collapse
          in={step === 3}
          timeout={400}
          exit={false}
          mountOnEnter={true}
          unmountOnExit={true}
        >
          <StepThree step={step} setStep={setStep} />
        </Collapse>
      </div>
    </LayoutV2>
  );
}

function StepOne(props) {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  return (
    <>
      <div
        className={
          "w-[60%] min-h-screen h-screen mx-auto my-auto no-scrollbar overflow-scroll pt-[30vh] pb-[30vh]"
        }
      >
        <h1 className={"text-center text-xl"}>
          Welcome! Let’s take 1 minute to customize your experience.
        </h1>
        <Collapse
          direction="bottom"
          in={step > 0}
          timeout={500}
          exit={false}
          mountOnEnter={true}
          unmountOnExit={true}
        >
          <div>
            <div
              className={"rounded-lg w-[80%] mx-auto bg-white mt-8 shadow-md"}
            >
              <CustomSelectV1
                label={"Who are your target audience?"}
                options={[
                  { value: "users", label: "Users" },
                  { value: "developers", label: "Developers" },
                  { value: "product managers", label: "Product Managers" },
                  { value: "business analysts", label: "Business Analysts" },
                ]}
              />
            </div>
          </div>
        </Collapse>
        <Fade in={step > 1}>
          <div className={"w-max mx-auto"}>
            <CustomHorizontalConnector />
          </div>
        </Fade>
        <Collapse
          direction="bottom"
          in={step > 1}
          exit={false}
          timeout={500}
          mountOnEnter={true}
          unmountOnExit={true}
        >
          <div>
            <div className={"rounded-lg w-[80%] mx-auto bg-white shadow-md"}>
              <CustomSelectV1
                label={"what team are you working in?"}
                options={[
                  { value: "users", label: "Product Team" },
                  { value: "developers", label: "Marketing Team" },
                  { value: "product managers", label: "Development Team" },
                  {
                    value: "customer_success",
                    label: "Customer Success Team",
                  },
                  { value: "business analysts", label: "Others" },
                ]}
              />
            </div>
          </div>
        </Collapse>
        <Fade in={step > 2}>
          <div className={"w-max mx-auto"}>
            <CustomHorizontalConnector />
          </div>
        </Fade>
        <Collapse
          direction="bottom"
          in={step > 2}
          exit={false}
          timeout={500}
          mountOnEnter={true}
          unmountOnExit={true}
        >
          <div>
            <div className={"rounded-lg w-[80%] mx-auto bg-white shadow-md"}>
              <CustomSelectV1
                label={"Who are your target audience?"}
                options={[
                  { value: "users", label: "Users" },
                  { value: "developers", label: "Developers" },
                  { value: "product managers", label: "Product Managers" },
                  { value: "business analysts", label: "Business Analysts" },
                ]}
              />
            </div>
          </div>
        </Collapse>
      </div>
      <div
        className={
          "absolute bg-[#e8ecff] bottom-0 left-0 right-0 p-6 flex justify-end"
        }
      >
        {step > 2 ? (
          <Button
            onClick={() => {
              props.setStep(2);
            }}
            size={"large"}
            variant={"contained"}
            elevation={1}
          >
            Continue
          </Button>
        ) : (
          <Button
            onClick={() => {
              setStep(step + 1);
            }}
            size={"large"}
            variant={"contained"}
            elevation={1}
          >
            Next
          </Button>
        )}
      </div>
    </>
  );
}

function StepTwo(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const user = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState([false]);

  useEffect(() => {
    if (
      sessionStorage.getItem("id") === null ||
      sessionStorage.getItem("access-token") === null
    ) {
      sessionStorage.clear();
      localStorage.clear();
      navigate("/sign-in");
      return;
    }
    if (user.status === "idle") {
      dispatch(
        findUser({
          parameters: {
            id: sessionStorage.getItem("id"),
          },
          navigate,
        }),
      );
    }
  }, []);

  return (
    <div className={"max-w-[350px] h-max mx-auto my-auto"}>
      <div className={"w-full px-4 py-6 bg-white rounded-md"}>
        <p className={"text-center text-lg"}>set company name</p>

        <div className={"mt-8"}>
          <p className={"text-black text-xs"}>Company name</p>
          <TextField
            fullWidth={true}
            className={"!mt-3"}
            size={"small"}
            placeholder={user?.data?.workspace?.name}
            onChange={(e) => {
              setFormData({ name: e.target.value });
            }}
          />
        </div>

        <Button
          disabled={
            !formData.name || formData.name?.length < 3 || isLoading[0] === true
          }
          disableElevation={true}
          className={""}
          classes={{
            root: "!mt-6 !bg-blue-800 !rounded-md",
            disabled: "!bg-gray-400",
          }}
          variant={"contained"}
          fullWidth={true}
          onClick={async () => {
            setIsLoading((isLoading) => {
              isLoading[0] = true;
              return [...isLoading];
            });
            const response = await services.accounts.workspaces.single.update(
              {
                id: user.data.workspace._id,
              },
              {},
              { name: formData.name },
            );
            if (response.status === 200) {
              props.setStep(3);
            } else {
              enqueueSnackbar(
                response.data?.message || "Something went wrong ...",
              );
            }
          }}
        >
          {isLoading[0] === true ? <CircularProgress size={20} /> : "Continue"}
        </Button>
      </div>
    </div>
  );
}

function StepThree(props) {
  const navigate = useNavigate();
  return (
    <div className={"max-w-[350px] h-max mx-auto my-auto"}>
      <div className={"w-full px-4 py-6 bg-white rounded-md"}>
        <div className={"flex items-center justify-center gap-x-3 w-full"}>
          <div
            className={
              "size-7 bg-green-600 rounded-full flex items-center justify-center"
            }
          >
            <CheckIcon className={"!size-4 !text-white"} />
          </div>
          <p className={"font-semibold text-md"}>Onboarding completed!</p>
        </div>

        <Button
          disableElevation={true}
          className={"!mt-6 !bg-blue-800 !rounded-md"}
          variant={"contained"}
          fullWidth={true}
          onClick={() => {
            navigate("/dashboard/home");
          }}
        >
          Proceed to Dashboard
        </Button>
      </div>
    </div>
  );
}
