import { useEffect, useState } from "react";
import IssueChart from "./IssueChart";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { findProject } from "../../../../slices/project.slice";
import { useSelector, useDispatch } from "react-redux";
import services from "../../../../services";
import { Button } from "@mui/material";

function getMonthName(monthNumber) {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Month numbers are 1-based, so subtract 1 to get the correct index
  return monthNames[monthNumber - 1] || "Invalid month";
}
export default function Analytics() {
  const navigate = useNavigate();
  const [count, setCount] = useState({
    total: 0,
  });
  const [chart1, setChart1] = useState([]);
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const project = useSelector((state) => state.project);

  useEffect(() => {
    async function setup() {
      const response = await services.accounts.projects.single.issues.metrics({
        id: searchParams.get("id"),
      });
      console.log(response.status);
      if (response.status === 200) {
        setCount(response.data.data.count)
        setChart1(
          response.data.data.chart1.map((item, index) => {
            console.log(item);
            return {
              y: item.count,
              x:
                item._id.day.toString() +
                " " +
                getMonthName(item._id.month) +
                ", " +
                item._id.year,
            };
          }),
        );
      }
    }
    setup();
  }, []);

  useEffect(() => {
    if (
      project.status === "idle" ||
      project.data?._id !== searchParams.get("id")
    ) {
      dispatch(findProject({ parameters: { id: searchParams.get("id") } }));
    }
  }, [project.status]);

  return (
    <>
      <div>
        <h1 className="text-2xl font-semibold mt-6">Analytics</h1>
        <p className="text-xs font-light mt-3 text-gray-600">
          Get some insight on your reporting
        </p>
      </div>
      <div className="mt-6 rounded-md flex justify-center bg-white py-3">
        <div className="py-3 border-r w-full">
          <p className="text-center text-xl">
            {project.data?.issues?.length || 0}
          </p>
          <p className="text-center text-xs text-gray-500">Total Tickets</p>
        </div>
        <div className="py-3 border-r w-full">
          <p className="text-center text-xl">0</p>
          <p className="text-center text-xs text-gray-500">Open Tickets</p>
        </div>
        <div className="py-3 border-r w-full">
          <p className="text-center text-xl">0</p>
          <p className="text-center text-xs text-gray-500">Resolved Tickets</p>
        </div>
        <div className="py-3 w-full">
          <p className="text-center text-xl">0</p>
          <p className="text-xs text-center text-gray-500">Archived Tickets</p>
        </div>
      </div>
      <div className="mt-6 rounded-md bg-white p-6">
        <div className={"w-full flex justify-between items-center"}>
          <div>
            <p>Reported Issues</p>
          </div>
        </div>
        <div className="w-full mt-6 pt-3">
          {count.total > 0 ? (
            <IssueChart data={chart1} />
          ) : (
            <div className={"flex flex-col items-center justify-center"}>
              <p className={"text-lg"}>You currently have no submitted issue</p>
              <p className={"text-sm text-gray-500 my-3"}>
                Submit an issue to get started
              </p>
              <Button
                variant={"contained"}
                className={"mx-auto !bg-blue-700"}
                onClick={() => {
                  window.open(
                    `https://${project.data.website}`,
                    "_blank",
                    "noopener,noreferrer",
                  );
                }}
              >
                Submit an issue
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
