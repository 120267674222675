import { TextField, Button, Avatar } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { findUser } from "../../../slices/user.slice";
import { findWorkspace } from "../../../slices/workspace.slice";
import InviteMember from "../../../components/settings/workspace/members/InviteMember";
import { enqueueSnackbar } from "notistack";
import services from "../../../services";

export default function Members() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const workspace = useSelector((state) => state.workspace);
  const [isOpen, setIsOpen] = useState([
    false, // to open the invite member dialog
  ]);

  useEffect(() => {
    if (sessionStorage.getItem("id") === null) {
      navigate("/sign-in");
      return;
    }
    if (user.status === "idle") {
      dispatch(
        findUser({
          parameters: {
            id: sessionStorage.getItem("id"),
          },
          navigate,
        }),
      );
    }
    if (user.status === "success" && workspace.status === "idle") {
      dispatch(
        findWorkspace({
          parameters: {
            id: user.data.workspace._id,
          },
          navigate,
        }),
      );
    }
  }, [user.status, workspace.status]);
  return (
    <>
      <div className={"min-h-screen"}>
        <h1 className="text-2xl">Members</h1>
        <div className="rounded-lg bg-white flex justify-between items-center p-3">
          <TextField size="small" />
          <Button
            disableElevation={true}
            variant="contained"
            className="!bg-blue-700 !text-white"
            onClick={() => {
              setIsOpen((isOpen) => {
                isOpen[0] = true;
                return [...isOpen];
              });
            }}
          >
            Invite
          </Button>
        </div>
        <div className="mt-3">
          {workspace.data?.members?.map((member, index) => {
            return (
              <div
                key={index}
                className="flex justify-between items-center p-3 border-b"
              >
                <div className="flex items-center gap-x-3">
                  <Avatar
                    alt="member_avatar"
                    src={member.image}
                    className="!size-12"
                  />
                  <div>
                    <p>{`${member.firstName} ${member.lastName}`}</p>
                    <p className="text-xs font-light">{member.emailAddress}</p>
                  </div>
                </div>
                <div>
                  <Button
                    size={"small"}
                    variant={"contained"}
                    className={"!bg-red-600 !text-white"}
                    disableElevation={true}
                    onClick={async () => {
                      let members = workspace.data.members.filter(
                        (item) => member._id !== item._id,
                      );
                      const response =
                        await services.accounts.workspaces.single.update(
                          {
                            id: user.data.workspace._id,
                          },
                          {},
                          { members: members.map((member) => member._id) },
                        );
                      if (response.status !== 200) {
                        enqueueSnackbar(
                          response.data?.message || "Something went wrong!",
                        );
                      }
                      enqueueSnackbar("Workspace updated successfully!");
                    }}
                  >
                    Remove
                  </Button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <InviteMember
        workspace={workspace.data}
        isOpen={isOpen[0]}
        onClose={() =>
          setIsOpen((isOpen) => {
            isOpen[0] = false;
            return [...isOpen];
          })
        }
      />
    </>
  );
}
