import { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { Avatar, IconButton } from "@mui/material";
import { Link } from "iconsax-react";
import { useSearchParams } from "react-router-dom";
import { enqueueSnackbar } from "notistack";

export default function Video() {
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const [videoSrc, setVideoSrc] = useState("");
  useEffect(() => {
    console.log(searchParams.get("url"));
    // Fetch the video data from Cloudinary
    const fetchVideo = async () => {
      try {
        const response = await fetch(
          searchParams.get("url") ||
            "https://res.cloudinary.com/geergregrgege/video/upload/v1716427155/samples/dance-2.mp4",
        );
        if (!response.ok) {
          throw new Error("Failed to fetch video");
        }
        const videoBlob = await response.blob();
        const videoObjectURL = URL.createObjectURL(videoBlob);
        setVideoSrc(videoObjectURL);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching video:", error);
      }
    };

    fetchVideo();

    // Cleanup the object URL when the component unmounts
    return () => {
      if (videoSrc) {
        URL.revokeObjectURL(videoSrc);
      }
    };
  }, []);
  return (
    <>
      <div className={"flex justify-between p-3 border-b border-b-gray-200"}>
        <div>
          <Avatar
            src={
              "https://res.cloudinary.com/geergregrgege/image/upload/v1722773827/assets/images/usabul_logo_icon_white_bciudc.png"
            }
          />
        </div>
        <div className={"flex"}>
          <IconButton
            onClick={() => {
              enqueueSnackbar("Copied to clipboard!");
            }}
          >
            <Link />
          </IconButton>
        </div>
      </div>

      <div className="flex flex-col items-center justify-center min-h-screen text-white">
        <div className="w-full max-w-4xl p-4">
          <ReactPlayer
            url={videoSrc}
            controls
            width="100%"
            height="100%"
            className="rounded-lg shadow-lg"
          />
        </div>
        <footer className="mt-6 text-gray-400">
          Powered by{" "}
          <a href="https://usabul.io" className="underline">
            Usabul
          </a>
        </footer>
      </div>
    </>
  );
}
