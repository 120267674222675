import { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Avatar,
  Card,
  Typography,
  Divider,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import { findUser } from "../../../slices/user.slice";
import { useNavigate } from "react-router-dom";
import services from "../../../services";
import UpdatePassword from "../../../components/settings/personal/profile/UpdatePassword";

const Profile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
  });

  useEffect(() => {
    if (
      sessionStorage.getItem("id") === null ||
      sessionStorage.getItem("access-token") === null
    ) {
      sessionStorage.clear();
      localStorage.clear();
      navigate("/sign-in");
      return;
    }
    if (user.status === "idle") {
      dispatch(
        findUser({
          parameters: {
            id: sessionStorage.getItem("id"),
          },
          navigate,
        }),
      );
    }
  }, []);

  useEffect(() => {
    console.log(formData);
  }, [formData]);

  return (
    <>
      <div className="items-center min-h-screen">
        <h1 className="text-2xl">Profile</h1>
        <div className="w-full mt-10">
          <div className="py-6">
            {/* Basic Details */}
            <Card className="p-4 mb-6 flex justify-between">
              <Typography variant="subtitle1" className="mb-4">
                Basic details
              </Typography>

              <div className="w-[400px] space-y-3">
                <label
                  htmlFor="upload-avatar"
                  className={"cursor-pointer w-min"}
                >
                  <Avatar
                    alt="User Avatar"
                    src={formData.image || user.data?.image}
                    sx={{ width: 60, height: 60 }}
                  />
                </label>
                <div className="flex flex-col mb-4">
                  <Typography variant="text-xs text-gray-600">
                    Email Address
                  </Typography>
                  <Typography variant="body1" className="text-blue-500">
                    {user.data?.emailAddress}
                  </Typography>
                </div>
                <div className="flex flex-col">
                  <Typography variant="text-xs text-gray-600">
                    First Name
                  </Typography>
                  <TextField
                    size={"small"}
                    placeholder={user.data?.firstName}
                    defaultValue={user.data?.firstName}
                    onChange={(e) => {
                      setFormData({ ...formData, firstName: e.target.value });
                    }}
                  />
                </div>
                <div className="flex flex-col">
                  <Typography variant="text-xs text-gray-600">
                    Last Name
                  </Typography>
                  <TextField
                    size={"small"}
                    placeholder={user.data?.lastName}
                    defaultValue={user.data?.lastName}
                    onChange={(e) => {
                      setFormData({ ...formData, lastName: e.target.value });
                    }}
                  />
                </div>
                <Button
                  disabled={
                    formData.firstName.length < 3 &&
                    formData.lastName.length < 3
                  }
                  variant={"contained"}
                  disableElevation={true}
                  onClick={async () => {
                    let body = formData;
                    Object.keys(body).forEach((key, index) => {
                      if (body[key].length < 1) {
                        delete formData[key];
                      }
                    });

                    const response =
                      await services.accounts.users.single.update(
                        {
                          id: user.data._id,
                        },
                        body,
                      );
                  }}
                >
                  Save
                </Button>
              </div>
            </Card>
            <Divider className="mb-4" />
            {/* Password Section */}
            <UpdatePassword user={user} />
            <Divider className="mb-4" />
            {/* Delete Project Section */}
            <Card className="p-4 mb-6 flex justify-between">
              <Typography variant="subtitle1" className="mb-4 text-red-600">
                Delete account
              </Typography>
              <div className="w-[400px]">
                <p className="text-gray-400 mb-4">
                  After deleting your account, you'll receive an email
                  confirming that we removed all your data from our server.
                </p>
                <Button
                  variant="contained"
                  color="error"
                  startIcon={<DeleteIcon />}
                  className="!w-max"
                >
                  Delete account
                </Button>
              </div>
            </Card>
          </div>
        </div>
      </div>
      <input
        accept="image/*"
        id="upload-avatar"
        type="file"
        style={{ display: "none" }}
        onChange={(event) => {
          const file = event.target.files[0];
          if (file) {
            const reader = new FileReader();
            reader.onload = async () => {
              const base64Image = reader.result;
              setFormData({ ...formData, image: base64Image });
              await services.accounts.users.single.update(
                { id: user.data?._id },
                { image: base64Image },
              );
            };
            reader.readAsDataURL(file); // Convert file to Base64
          }
        }}
      />
    </>
  );
};

export default Profile;
