import { Divider, Button } from "@mui/material";
import { useState } from "react";

export default function CustomSelectV1(props) {
  const [selectedOption, setSelectedOption] = useState(props.options[0].value);
  return (
    <div className={"w-full bg-none"}>
      <div className={"p-3"}>
        <p className={"text-gray-600"}>{props.label}</p>
      </div>
      <Divider />
      <div className={"p-3 flex flex-wrap gap-3"}>
        {props.options.map((option, index) => (
          <Button
            disableElevation={true}
            key={index}
            variant={selectedOption === option.value ? "contained" : "outlined"}
            size="small"
            className={`!hover:border-blue-500  ${
              selectedOption === option.value ? "!text-white" : "!text-gray-600"
            }`}
            onClick={() => setSelectedOption(option.value)}
          >
            {option.label}
          </Button>
        ))}
      </div>
    </div>
  );
}
